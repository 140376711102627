import React, { useCallback, useEffect, useRef, useState } from "react";
import VerticalLinearStepper from "./Stepper";

import camera from "../../assets/Icon/camera-icon.svg";
import { useTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import Util from "../../Util";
import ApiCalling from "../../network/ApiCalling";
import Button from "@mui/material/Button";
import UserIMG from "../../assets/Icon/default-profile.png";
import Calander from "../../assets/Icon/calendar.svg";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import EditPhotoModal from "../Dashbord/EditPhotoModal";
import { useHistory } from "react-router-dom";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Loader from "../../components/common/Loader";
import * as faceapi from "face-api.js";
import ToastHOC from "../HOC/ToastHOC";
import Invalid from "../../assets/Icon/invalid.svg";
import ModalComponent from "../Dashbord/ModalComponent";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import ExitAlert from "../../components/ExitAlert";
import axios from "axios";

const AddNewStudent = (props) => {
  const { setIsToast } = props;
  const datepickerRef = useRef(null);
  const { setIsSubToast } = props;
  const pendingToast = props.location.pendingToast;
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [indexPage, setIndexPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // for step 0
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("AE");
  const [dateOfBirth, setDate] = useState(null);
  const [nationalityId, setNationalityId] = useState(0);
  const [country, setCountry] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [photo, setPhoto] = useState(null);
  const [isFaceDetection, setIsFaceDetection] = useState(false);
  const [faceError, setfaceError] = useState(false);
  const [faceDetail, setFaceDetail] = useState(null);
  const [faceDetection, setFaceDetection] = useState(null);
  const canvasREF = React.useRef();
  const photoREF = React.useRef();
  const inputref = React.useRef();
  // for step 1

  const [stageList, setStageList] = useState([]);
  const [stageId, setStageId] = useState(0);
  const [stageIdError, setStageIdError] = useState("");
  const [gradeId, setGradeId] = useState(0);
  const [gradeIdError, setGradeIdError] = useState("");
  const [roomId, setRoomId] = useState(0);
  const [roomIdError, setRoomIdError] = useState("");
  const [timingList, setTimingList] = useState([]);
  const [timingId, setTimingId] = useState(0);
  const [TermsList, setTermsList] = useState([]);
  const [termsId, setTermsId] = useState(0);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  // for step 3
  const [invitation, setInvitation] = useState(false);
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentFirstNameError, setParentFirstNameError] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentLastNameError, setParentLastNameError] = useState("");
  const [relationList, setRelationList] = useState([]);
  const [relationId, setRelationId] = useState(0);
  const [relationIdError, setRelationIdError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [age, setAge] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const stageSelectRef = useRef(null);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);

  const handleImage = async () => {
    const detection = await faceapi
      .detectSingleFace(photoREF.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptor();
    setIsFaceDetection(false);
    // let p = new faceapi.LabeledFaceDescriptors(`${firstName}`,[detection.descriptor])
    // setFaceDetail(p.toJSON());

    if (detection !== undefined) {
      setFaceDetail(detection.descriptor.toString());
      canvasREF.current.innerHtml = faceapi.createCanvasFromMedia(
        photoREF.current
      );
      faceapi.matchDimensions(canvasREF.current, {
        width: 300,
        height: 300,
      });
      const resized = faceapi.resizeResults(detection, {
        width: 300,
        height: 300,
      });
      faceapi.draw.drawDetections(canvasREF.current, resized);
    }
  };

  useEffect(() => {
    if (photo !== 0 && photoREF.current) {
      setfaceError("");
      setIsFaceDetection(true);
      const loadModels = () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]).then((values) => {
          handleImage();
        });
      };
      photoREF && loadModels();
    }
  }, [photo]);

  const steps = [
    {
      label: `${t("Personal Information")}`,
    },
    {
      label: `${t("School Information")}`,
    },
    {
      label: `${t("Parent Information")}`,
    },
  ];
  const handleNext = () => {
    if (indexPage === 0) {
      step1();
    }
    if (indexPage === 1) {
      step2();
    }
    if (indexPage === 2) {
      step3();
    }
  };

  const handleBack = () => {
    if (indexPage === 0) {
      history.push("/student");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIndexPage((indexPage) => indexPage - 1);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setIndexPage(0);
  };

  const step1 = () => {
    let error = false;
    setFirstNameError("");
    setLastNameError("");
    setPhoneError("");
    if (firstName == "") {
      setFirstNameError(t("First Name is required"));
      error = true;
    }
    if (lastName == "") {
      setLastNameError(t("Last Name is required"));
      error = true;
    }
    if (phone == null || phone == undefined) {
      setPhoneError(t("Phone Number is required"));
      error = true;
    }
    if (!error) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIndexPage((indexPage) => indexPage + 1);
      if (faceDetail !== null) {
        setFaceDetection(faceDetail);
      }
    }
  };
  const step2 = () => {
    let error = false;
    setStageIdError("");
    setGradeIdError("");
    setRoomIdError("");
    if (stageId == 0) {
      setStageIdError(t("Level is required"));
      error = true;
    }
    if (gradeId == 0) {
      setGradeIdError(t("Grade is required"));
      error = true;
    }
    if (roomId == 0) {
      setRoomIdError(t("Room is required"));
      error = true;
    }

    if (!error) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIndexPage((indexPage) => indexPage + 1);
      stageSelectRef.current.focus();
    }
  };
  const step3 = () => {
    setIsStateUpdated(false);
    let error = false;

    setParentFirstNameError("");
    setParentLastNameError("");
    setRelationIdError("");
    setEmailError("");
    if (parentFirstName == "") {
      setParentFirstNameError(t("First Name is required"));
      error = true;
    }
    if (parentLastName == "") {
      setParentLastNameError(t("Last Name is required"));
      error = true;
    }
    if (relationId == 0) {
      setRelationIdError(t("relation is required"));
      error = true;
    }
    if (email == "") {
      setEmailError(t("email is required"));
      error = true;
    } else if (Util.email_regex.test(email) === false) {
      setEmailError(t("Invalid Email"));
      error = true;
    }
    if (phone == null || phone == undefined) {
      setPhoneError(t("Phone Number is required"));
      error = true;
    }
    if (!error) {
      const formData = new FormData();
      if (firstName) {
        formData.append("first_name", firstName);
      }
      if (lastName) {
        formData.append("last_name", lastName);
      }

      if (dateOfBirth) {
        formData.append("dob", new Date(dateOfBirth).toISOString());
      }
      if (phone) {
        formData.append("phone_number", phone);
      }
      if (termsId) {
        formData.append("terms_id", termsId);
      }
      if (timingId) {
        formData.append("timing_id", timingId);
      }
      if (roomId) {
        formData.append("classroom_id", roomId);
      }
      if (gradeId) {
        formData.append("grade_id", gradeId);
      }
      if (photo) {
        formData.append("photo", photo);
      }
      if (nationalityId) {
        formData.append("nationality_id", nationalityId);
      }
      formData.append("monday", monday);

      formData.append("tuesday", tuesday);

      formData.append("wednesday", wednesday);

      formData.append("thursday", thursday);

      formData.append("friday", friday);

      if (parentFirstName) {
        formData.append("parent_firstName", parentFirstName);
      }
      if (parentLastName) {
        formData.append("parent_lastName", parentLastName);
      }
      if (email) {
        formData.append("parent_email", email);
      }
      if (cityId) {
        formData.append("parent_city_id", cityId);
      }
      if (stateId) {
        formData.append("parent_state_id", stateId);
      }
      if (countryId) {
        formData.append("parent_country_id", countryId);
      }
      if (relationId) {
        formData.append("student_parent_relation_type_id", relationId);
      }
      if (faceDetection) {
        formData.append("face", JSON.stringify(faceDetection));
      }
      formData.append("send_mail", invitation);
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      ApiCalling.apiCallBodyDataPost("student/create-student-parent", formData)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            setIsClickedOnce(false);
            reset();
            history.push({
              pathname: "/student",
              pendingToast: `${firstName + " " + lastName} ${t(
                "registered successfully"
              )}`,
            });
          } else {
            setIsLoading(false);
            setIsClickedOnce(false);
            setEmailError(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const reset = () => {
    setFirstNameError("");
    setLastNameError("");
    setPhoneError("");
    setStageIdError("");
    setGradeIdError("");
    setRoomIdError("");
    setParentFirstNameError("");
    setParentLastNameError("");
    setRelationIdError("");
    setEmailError("");
  };

  const countryList = async () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setCountry(res?.data);
    });
  };
  const getStateList = async () => {
    setIsCountryLoading(true);
    ApiCalling.apiCallBodyDataGet(`state/get-states/${countryId}`).then(
      (res) => {
        setIsCountryLoading(false);
        setStateList(res.data);
      }
    );
  };
  const cityList = async (id) => {
    ApiCalling.apiCallBodyDataGet(`city/list/${stateId}`).then((res) => {
      setCity(res?.data);
    });
  };
  const getTimingList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("timing/list").then((res) => {
      setIsLoading(false);
      setTimingList(res.data);
    });
  };
  const getTermsList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("terms/school").then((res) => {
      setIsLoading(false);
      setTermsList(res.data);
    });
  };
  const getStageList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setIsLoading(false);
        setStageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRelationList = async () => {
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setRelationList(res.data);
    });
  };

  useEffect(() => {
    if (stateId) {
      cityList();
    }
  }, [stateId]);
  useEffect(() => {
    if (countryId) {
      getStateList();
    }
  }, [countryId]);
  useEffect(() => {
    countryList();
    getTimingList();
    getStageList();
    getRelationList();
    getTermsList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  const changeDate = (date) => {
    setDate(date);
    const calculatedAge = Util.calculateAge(date);
    setAge(calculatedAge);
    setIsStateUpdated(true);
    // datepickerRef.current.state.open(false);
  };
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className=" d-flex" onClick={onClick}>
      <input ref={ref} className="custom-input__field" value={value} readOnly />
      <div className="custom-input__icon">
        <img src={Calander} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const handlePhotoChange = (e) => {
    setFaceDetail(null);
    if (e.target.files[0]) {
      setShowEditModal(true);
    }
    setPhoto(e.target.files[0]);
    setPhotoURL(URL.createObjectURL(e.target.files[0]));
    setIsStateUpdated(true);
  };
  const handleSavePhoto = (e) => {
    setShowEditModal(false);
  };
  const sendInvitation = (e) => {
    setInvitation(e.target.checked);
    setIsStateUpdated(true);
  };
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        // console.log("FETCH COUNTRY:::", res);
        setDefaultCountry(res.data.country);
      })
      .catch((error) => {
        console.log("error", error);
      });
    // const browserLanguage = navigator.language || navigator.userLanguage;
    // let countryCode = "";
    // if (browserLanguage) {
    //   if (browserLanguage.includes("-")) {
    //     countryCode = browserLanguage.split("-")[1].toUpperCase();
    //   } else {
    //     countryCode = browserLanguage.toUpperCase();
    //   }
    //   setDefaultCountry(countryCode);
    // }
  }, []);

  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/new-student" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);
  return (
    <>
      {!isLoading ? (
        <>
          <div className="container">
            <div className="row mb-2 py-4">
              <BreadCrumbComponent
                Breadcrumb={[
                  {
                    name: "My School",
                    navigate: () => {
                      history.push("/Dashboard");
                    },
                  },
                  {
                    name: "Student List",
                    navigate: () => {
                      history.push("/student");
                    },
                  },
                  { name: `${t("Add New Student")}` },
                ]}
              />
              <div className="col-lg">
                <h2 className="page-head text-center">
                  {t("Add New Student")}
                </h2>
              </div>
            </div>
            <div className="row mt-0">
              <div className="col-lg-3">
                <VerticalLinearStepper
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleReset={handleReset}
                />
              </div>
              <div className="col-lg-6 ">
                {indexPage == 0 && (
                  <>
                    <div className="form-wrappr">
                      <h5 className="drawer-sub-header">
                        {t("Personal Information")}
                      </h5>
                      <div
                        className="photo-picker"
                        style={{ position: "relative", marginBottom: "25px" }}
                      >
                        <p className="text-start">{t("Photo")}</p>
                        {photoURL.length > 0 ? (
                          <div
                            className="profile-area"
                            style={{
                              position: "relative",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt="User"
                              className="rounded-circle user-photo img-responsive  mt-1 "
                              src={photoURL}
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />{" "}
                            <span
                              className="choose-profile"
                              onClick={(e) => {
                                e.preventDefault();
                                // document.getElementById("photo").click();
                                setShowEditModal(true);
                              }}
                            >
                              <img src={camera} alt="" />
                            </span>
                          </div>
                        ) : (
                          <div
                            className="profile-area"
                            style={{
                              position: "relative",
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt="User"
                              className="img-responsive  mt-1"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                position: "relative",
                              }}
                              src={UserIMG}
                            />
                            <span
                              className="choose-profile"
                              onClick={(e) => {
                                e.preventDefault();
                                document.getElementById("photo").click();
                              }}
                            >
                              <img src={camera} alt="" />
                            </span>

                            <input
                              className="sr-only"
                              id="photo"
                              type="file"
                              accept="image/*"
                              onChange={handlePhotoChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row text-start mt-3">
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <span className="basic-text required">
                            {t("First Name")}
                          </span>
                          <input
                            className={`form-control custom-input ${
                              firstNameError.length > 0 ? "input-error" : ""
                            }`}
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              setFirstNameError("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {firstNameError.length > 0 && (
                            <span className=" invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {firstNameError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text  required">
                            {t("Last Name")}
                          </span>
                          <input
                            className={`form-control custom-input ${
                              lastNameError.length > 0 ? "input-error" : ""
                            }`}
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                              setLastNameError("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {lastNameError.length > 0 && (
                            <span className="invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {lastNameError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <span className="basic-text">
                            {t("Date of Birth")}
                          </span>

                          <DatePicker
                            ref={datepickerRef}
                            placeholderText="Select Date"
                            selected={dateOfBirth}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={changeDate}
                            dateFormat={school.date_format}
                            // wrapperClassName={` ${
                            //   dateOfBirthError.length > 0 ? "datePicker-error" : ""
                            // }`}
                            popperPlacement="bottom"
                            maxDate={new Date()}
                            customInput={<CustomInput />}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Phone Number")}
                          </span>

                          <>
                            <div
                              className={`form-control ${
                                phoneError.length > 0 ? "input-error" : ""
                              }`}
                              tabIndex={0}
                            >
                              <PhoneInput
                                tabIndex="-1"
                                international
                                defaultCountry={defaultCountry}
                                value={phone}
                                onChange={(value) => {
                                  setPhone(value);
                                  setPhoneError("");
                                  setIsStateUpdated(true);
                                }}
                                // ref={inputref}
                                // onKeyDown={handleKeyDown}
                                // tabIndex={0}
                              />
                            </div>
                            {phoneError.length > 0 && (
                              <span className=" invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {phoneError}
                              </span>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Age")}</span>

                          <div
                            className="basic-large-text"
                            style={{ marginLeft: "12px", marginTop: "7px" }}
                          >
                            {Util.isValidData(dateOfBirth) ? (
                              <>
                                {age?.years} {t("years")} {age?.months}{" "}
                                {t("months")}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("Nationality")}</span>
                          <select
                            name=""
                            id=""
                            className="select-dropdown form-select custom-input"
                            value={nationalityId}
                            onChange={(e) => {
                              setNationalityId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0}>
                              {t("Select Nationality")}
                            </option>
                            {country?.map(
                              (item) =>
                                item.nationality && (
                                  <option
                                    placeholder={t("Nationality")}
                                    key={item.id}
                                    value={item.id}
                                  >
                                    {item.nationality}
                                  </option>
                                )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {indexPage === 1 && (
                  <>
                    <div className="row text-start mt-3">
                      <h5 className="drawer-sub-header mb-3">
                        {t("School Information")}
                      </h5>
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <span className="basic-text  required">
                            {t("Level")}
                          </span>
                          <select
                            className={`select-dropdown form-select custom-input ${
                              stageIdError.length > 0 ? "input-error" : ""
                            }`}
                            ref={stageSelectRef}
                            value={stageId}
                            onChange={(e) => {
                              setStageId(e.target.value);
                              setStageIdError("");
                              setGradeId(0);
                              setRoomId(0);
                              setIsStateUpdated(true);
                            }}
                            autoFocus={indexPage === 1}
                          >
                            <option value={0}>{t("Select Level")}</option>
                            {stageList?.map((item) => (
                              <option
                                placeholder={t("Level")}
                                key={item.id}
                                value={item.id}
                              >
                                {item.stage}
                              </option>
                            ))}
                          </select>
                          {stageIdError.length > 0 && (
                            <span className=" invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {stageIdError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Grade")}
                          </span>
                          <select
                            className={`select-dropdown form-select custom-input ${
                              gradeIdError.length > 0 ? "input-error" : ""
                            }`}
                            value={gradeId}
                            onChange={(e) => {
                              setGradeId(e.target.value);
                              setGradeIdError("");
                              setIsStateUpdated(true);
                            }}
                            disabled={stageId == 0}
                          >
                            <option value={0}>{t("Select Grade")}</option>
                            {stageList
                              ?.find((stage) => stage.id === parseInt(stageId))
                              ?.Grades.map((grade) => (
                                <option key={grade.id} value={grade.id}>
                                  {grade.title}
                                </option>
                              ))}
                          </select>
                          {gradeIdError.length > 0 && (
                            <span className=" invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {gradeIdError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Classroom")}
                          </span>
                          <select
                            className={`select-dropdown form-select custom-input ${
                              roomIdError.length > 0 ? "input-error" : ""
                            }`}
                            value={roomId}
                            onChange={(e) => {
                              setRoomId(e.target.value);
                              setRoomIdError("");
                              setIsStateUpdated(true);
                            }}
                            disabled={gradeId === 0}
                          >
                            <option value={0}>{t("Select Room")}</option>
                            {stageList
                              .find((stage) => stage.id === parseInt(stageId))
                              ?.Grades.find(
                                (grade) => grade.id === parseInt(gradeId)
                              )
                              ?.ClassRooms.map((classroom) => (
                                <option key={classroom.id} value={classroom.id}>
                                  {classroom.name}
                                </option>
                              ))}
                          </select>
                          {roomIdError.length > 0 && (
                            <span className=" invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {roomIdError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("Timing")}</span>
                          <select
                            className="select-dropdown form-select custom-input"
                            value={timingId}
                            onChange={(e) => {
                              setTimingId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option value={0}>{t("Select Timing")}</option>
                            {timingList.map((t) => {
                              return (
                                <option key={t.id} value={t.id}>
                                  {t.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">
                            {t("Days Per Week")}
                          </span>

                          <div className="circle-container mt-2">
                            <div>
                              <label
                                className={`circulercheckbox ${
                                  monday ? "checked" : ""
                                }`}
                                tabIndex="0"
                              >
                                <input
                                  type="checkbox"
                                  checked={monday}
                                  onChange={() => {
                                    setMonday(!monday);
                                    setIsStateUpdated(true);
                                  }}
                                />
                                <span
                                  className={`circulercheckbox-letter ${
                                    monday ? "checked" : ""
                                  }`}
                                >
                                  {t("M")}
                                </span>
                              </label>
                            </div>
                            <div>
                              <label
                                className={`circulercheckbox ${
                                  tuesday ? "checked" : ""
                                }`}
                                tabIndex="0"
                              >
                                <input
                                  type="checkbox"
                                  checked={tuesday}
                                  onChange={() => {
                                    setTuesday(!tuesday);
                                    setIsStateUpdated(true);
                                  }}
                                />
                                <span
                                  className={`circulercheckbox-letter ${
                                    tuesday ? "checked" : ""
                                  }`}
                                >
                                  {t("T")}
                                </span>
                              </label>
                            </div>
                            <div>
                              <label
                                className={`circulercheckbox ${
                                  wednesday ? "checked" : ""
                                }`}
                                tabIndex="0"
                              >
                                <input
                                  type="checkbox"
                                  checked={wednesday}
                                  onChange={() => {
                                    setWednesday(!wednesday);
                                    setIsStateUpdated(true);
                                  }}
                                />
                                <span
                                  className={`circulercheckbox-letter ${
                                    wednesday ? "checked" : ""
                                  }`}
                                >
                                  {t("W")}
                                </span>
                              </label>
                            </div>
                            <div>
                              <label
                                className={`circulercheckbox ${
                                  thursday ? "checked" : ""
                                }`}
                                tabIndex="0"
                              >
                                <input
                                  type="checkbox"
                                  checked={thursday}
                                  onChange={() => {
                                    setThursday(!thursday);
                                    setIsStateUpdated(true);
                                  }}
                                />
                                <span
                                  className={`circulercheckbox-letter ${
                                    thursday ? "checked" : ""
                                  }`}
                                >
                                  {t("T")}
                                </span>
                              </label>
                            </div>
                            <div>
                              <label
                                className={`circulercheckbox ${
                                  friday ? "checked" : ""
                                }`}
                                tabIndex="0"
                              >
                                <input
                                  type="checkbox"
                                  checked={friday}
                                  onChange={() => {
                                    setFriday(!friday);
                                    setIsStateUpdated(true);
                                  }}
                                />
                                <span
                                  className={`circulercheckbox-letter ${
                                    friday ? "checked" : ""
                                  }`}
                                >
                                  {t("F")}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("Terms")}</span>
                          <select
                            className="select-dropdown form-select custom-input "
                            value={termsId}
                            onChange={(e) => {
                              setTermsId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option value={0}>{t("Select Terms")}</option>
                            {TermsList.map((t) => {
                              return (
                                <option key={t.id} value={t.id}>
                                  {t.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {indexPage === 2 && (
                  <>
                    <div className="row text-start mt-3">
                      <h5 className="drawer-sub-header mb-3">
                        {t("Parent Information")}
                      </h5>

                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text  required">
                            {t("First Name")}
                          </span>
                          <input
                            className={`form-control custom-input ${
                              parentFirstNameError.length > 0
                                ? "input-error"
                                : ""
                            }`}
                            value={parentFirstName}
                            onChange={(e) => {
                              setParentFirstName(e.target.value);
                              setParentFirstNameError("");
                              setIsStateUpdated(true);
                            }}
                            autoFocus={indexPage === 2}
                          />
                          {parentFirstNameError.length > 0 && (
                            <span className=" invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {parentFirstNameError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text  required">
                            {t("Last Name")}
                          </span>
                          <input
                            className={`form-control custom-input ${
                              parentLastNameError.length > 0
                                ? "input-error"
                                : ""
                            }`}
                            value={parentLastName}
                            onChange={(e) => {
                              setParentLastName(e.target.value);
                              setParentLastNameError("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {parentLastNameError.length > 0 && (
                            <span className="invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {parentLastNameError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text required">
                            {t("Relation")}
                          </span>
                          <select
                            className={`select-dropdown form-select custom-input ${
                              relationIdError.length > 0 ? "input-error" : ""
                            }`}
                            value={relationId}
                            onChange={(e) => {
                              setRelationId(e.target.value);
                              setRelationIdError("");
                              setIsStateUpdated(true);
                            }}
                          >
                            <option disabled value={0} selected>
                              {t("Relation")}
                            </option>
                            {relationList.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.type}
                                </option>
                              );
                            })}
                          </select>
                          {relationIdError.length > 0 && (
                            <span className="invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {relationIdError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <span className="basic-text required">
                            {t("Phone Number")}
                          </span>

                          <>
                            <div
                              className={`form-control ${
                                phoneError.length > 0 ? "input-error" : ""
                              }`}
                              tabIndex={0}
                            >
                              <PhoneInput
                                tabIndex="-1"
                                international
                                defaultCountry={defaultCountry}
                                value={phone}
                                onChange={(value) => {
                                  setPhone(value);
                                  setPhoneError("");
                                  setIsStateUpdated(true);
                                }}
                              />
                            </div>
                            {phoneError.length > 0 && (
                              <span className="invalid-error">
                                <img
                                  className="fa fa-exclamation-circle mr-2 invalid-svg"
                                  src={Invalid}
                                />
                                {phoneError}
                              </span>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text  required">
                            {t("Email Address")}
                          </span>
                          <input
                            className={`form-control custom-input ${
                              emailError.length > 0 ? "input-error" : ""
                            }`}
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailError("");
                              setIsStateUpdated(true);
                            }}
                          />
                          {emailError.length > 0 && (
                            <span className="invalid-error">
                              <img
                                className="fa fa-exclamation-circle mr-2 invalid-svg"
                                src={Invalid}
                              />
                              {emailError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed">
                          <span className="basic-text">{t("Country")}</span>
                          <select
                            className="select-dropdown form-select custom-input"
                            value={countryId}
                            onChange={(e) => {
                              setCountryId(e.target.value);
                              setStateId(0);
                              setCityId(0);
                              setIsStateUpdated(true);
                            }}
                          >
                            <option value={0}>{t("Select Country")}</option>
                            {country?.map((item) => (
                              <option
                                placeholder="Country"
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text ">{t("State")}</span>
                          <select
                            className="select-dropdown form-select custom-input"
                            value={stateId}
                            onChange={(e) => {
                              setStateId(e.target.value);
                              setCityId(0);
                              setIsStateUpdated(true);
                            }}
                            disabled={!countryId}
                          >
                            <option value={0}>
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select State")}
                            </option>
                            {stateList.map((item) => (
                              <option
                                placeholder="city"
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-filed ">
                          <span className="basic-text">{t("City")}</span>
                          <select
                            className="select-dropdown form-select custom-input"
                            value={cityId}
                            onChange={(e) => {
                              setCityId(e.target.value);
                              setIsStateUpdated(true);
                            }}
                            disabled={!stateId}
                          >
                            <option value={0}>
                              {isCountryLoading
                                ? t("Loading...")
                                : t("Select City")}
                            </option>
                            {city.map((item) => (
                              <option
                                placeholder="city"
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {indexPage !== steps.length ? (
                  <>
                    <div className="row">
                      <div className="d-flex  align-items-center mt-3 justify-content-between">
                        <div>
                          <button
                            className="btn secondary-lg-btn"
                            onClick={handleBack}
                          >
                            {indexPage === 0 ? t("Cancel") : t("Previous")}
                          </button>
                        </div>

                        <div>
                          <button
                            className="btn primary-btn "
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? t("Finish")
                              : t("Continue")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {indexPage == 2 && (
                  <div className="row mt-3">
                    <div className="col-lg d-flex ">
                      <p className="font-color">
                        {t("Send Invitation to sign up on Classadia")}
                      </p>
                      <label
                        className="fancy-checkbox element-left"
                        style={{ marginLeft: "90px" }}
                      >
                        <input
                          type="checkbox"
                          name="allselect"
                          value={invitation}
                          onChange={sendInvitation}
                        />
                        <span tabIndex="0"></span>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <EditPhotoModal
                show={showEditModal}
                onClose={() => {
                  setShowEditModal(false);
                }}
                title={t("Edit Photo")}
                subTitle={t(
                  "Show profile picture the best version of yourself"
                )}
                onChange={t("Change Photo")}
                onSave={t("Save Photo")}
                ProfilePhoto={UserIMG}
                isFaceDetection={isFaceDetection}
                photoURL={photoURL}
                photoREF={photoREF ? photoREF : undefined}
                canvasREF={canvasREF}
                handlePhotoChange={handlePhotoChange}
                handleSavePhoto={handleSavePhoto}
                faceDetail={faceDetail}
                faceError={faceError}
                clearFaceError={() => {
                  if (faceError) {
                    setfaceError();
                  }
                }}
              />
            </div>
            {/* {alertModal && (
              <ModalComponent
                show={alertModal}
                onHide={() => {
                  setIsAlertModal(false);
                }}
                title={""}
                subTitle={`Are you sure you want to navigate from this page? All the changes you made will not be saved`}
                size="sm"
                onCancel="Leave Page"
                onConfirm="Stay on Page"
              />
            )} */}
            {/* <Prompt
              when={() => {
                setIsLoading(false);
              }}
              message={() =>
                "Are you sure you want to navigate from this page? All the changes you made will not be saved"
              }
            /> */}
            <ExitAlert
              visible={alertModal}
              onClose={() => setIsAlertModal(false)}
              onOk={() => {
                setTriggerExit((obj) => ({
                  ...obj,
                  onOk: true,
                }));
                setIsAlertModal(false);
              }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ToastHOC(AddNewStudent);
