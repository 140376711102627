import { Box, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExitAlert from "../../../components/ExitAlert";
import Calender from "../../../assets/Icon/calendar.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import Loader from "../../../components/common/Loader";
import { ToastComponent } from "../../HOC/ToastHOC";

function CreateBillingPlan({ show, onClose, onSave, updateData }) {
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const getLanguage = Util.getSelectedLanguage();
  const datepickerRef = useRef(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [period, setPeriod] = useState("");
  const [periodError, setPeriodError] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [feeTypeList, setFeeTypeList] = useState([]);
  const [feeTypeId, setFeeTypeId] = useState(0);
  const [feeTypeIdError, setFeeTypeIdError] = useState("");
  const [saveInLibrary, setSaveInLibrary] = useState(false);
  const [enabledEndDates, setEnabledEndDates] = useState([]);
  const [lastPaymentDaysList, setLastPaymentDaysList] = useState([]);
  const [lastPaymentDay, setLastPaymentDay] = useState(0);
  const [lastPaymentDayError, setLastPaymentDayError] = useState("");
  const [billingPlanLibraryId, setBillingPlanLibraryId] = useState(0);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billPlanList, setBillPlanList] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastSubtitle, setToastSubtitle] = useState("");
  const [toastType, setToastType] = useState("success");

  const periodList = [
    { value: "WEEKLY", name: "WEEKLY" },
    { value: "BY-WEEKLY", name: "BY-WEEKLY" },
    { value: "MONTHLY", name: "MONTHLY" },
  ];

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          startDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));

  const handleName = (e) => {
    setName(e.target.value);
    setNameError("");
    setIsStateUpdated(true);
  };
  const handleStartDate = (date) => {
    setStartDate(date);
    setStartDateError("");
    getSelectedPeriod(period, date);
    setIsStateUpdated(true);
  };

  const handleLastPaymentDay = (e) => {
    const days = e.target.value;
    setLastPaymentDay(days);
    setLastPaymentDayError("");
    setIsStateUpdated(true);
  };

  const getSelectedPeriod = (period, date) => {
    if (period === "WEEKLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setDate(currentDate.getDate() + 7);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );

      setEnabledEndDates(formattedDate);
      fillDays(7);
    } else if (period === "BY-WEEKLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setDate(currentDate.getDate() + 14);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );

      setEnabledEndDates(formattedDate);
      fillDays(15);
    } else if (period === "MONTHLY") {
      const startDateObj = new Date(date);
      const endDateObj = new Date("2099-12-31");
      const enabledDates = [];
      enabledDates.push(startDateObj);

      let currentDate = new Date(startDateObj);
      while (currentDate < endDateObj) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        enabledDates.push(new Date(currentDate));
      }
      const formattedDate = enabledDates.map((d) =>
        moment(d).format("YYYY-MM-DD")
      );

      setEnabledEndDates(formattedDate);
      fillDays(30);
    } else {
      setEnabledEndDates([]);
    }
  };

  const handlePeriod = (e) => {
    setIsStateUpdated(true);
    const selectedPeriod = e.target.value;
    setPeriod(selectedPeriod);
    setPeriodError("");
    getSelectedPeriod(selectedPeriod, startDate);
    setEndDate(null);
  };

  const fillDays = (days) => {
    const arr = [];
    for (var i = 1; i < days; i++) {
      arr.push({ value: i, name: i + " day(s) after invoice generation date" });
    }
    setLastPaymentDaysList(arr);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setIsStateUpdated(true);
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);
    setAmountError("");
    setIsStateUpdated(true);
  };
  const handleFeeTypeId = (e) => {
    setFeeTypeId(e.target.value);
    setFeeTypeIdError("");
    setIsStateUpdated(true);
  };
  const handleSaveInLibrary = (e) => {
    setSaveInLibrary(!saveInLibrary);
    setIsStateUpdated(true);
  };
  const handleBillingPlanLibraryId = (e) => {
    setIsStateUpdated(true);
    setBillingPlanLibraryId(e.target.value);
    const templatePlan = billPlanList.find((obj) => {
      return obj.id === Number(e.target.value);
    });

    if (templatePlan !== undefined) {
      setName(templatePlan.name);
      setPeriod(templatePlan.period);
      setLastPaymentDay(templatePlan.last_payment_date);
      setFeeTypeId(templatePlan.fee_type_id);
      setAmount(templatePlan.amount);
    } else {
      setName("");
      setPeriod("");
      setLastPaymentDay(0);
      setFeeTypeId(0);
      setAmount("");
      setBillingPlanLibraryId(0);
    }
  };
  const handleSave = () => {
    setIsStateUpdated(false);
    let error = false;
    setNameError("");
    setStartDateError("");
    setPeriodError("");
    setAmountError("");
    setFeeTypeIdError("");
    setLastPaymentDayError("");
    if (name === "") {
      setNameError(t("Name is required"));
      error = true;
    }
    if (startDate == null) {
      setStartDateError(t("Start Date is required"));
      error = true;
    }
    if (period === "") {
      setPeriodError(t("Period is required"));
      error = true;
    }
    if (amount === "") {
      setAmountError(t("Amount is required"));
      error = true;
    }
    if (feeTypeId === 0) {
      setFeeTypeIdError(t("Fee Type is required"));
      error = true;
    }

    if (lastPaymentDay === 0) {
      setLastPaymentDayError(
        t("Last Payment date of invoice After is required")
      );
      error = true;
    }

    if (!error) {
      setIsLoading(true);
      const start_date = moment(startDate).format("YYYY-MM-DD");
      const end_date = moment(endDate).format("YYYY-MM-DD");
      const data = {
        name: name,
        start_date: start_date,
        end_date: end_date,
        period: period,
        save_in_library: saveInLibrary,
        fee_type_id: feeTypeId,
        amount: amount,
        last_payment_date: lastPaymentDay,
      };
      ApiCalling.apiCallBodyDataPost("billing-plans/add", data)
        .then((res) => {
          if (res.data?.status === true) {
            setIsLoading(false);
            onSave(res.data.message);
            onClose();
            updateData();
            reset();
          } else {
            setIsLoading(false);
            setShowNotification(true);
            setToastType("error");
            setToastTitle("Error");
            setToastSubtitle(res.data?.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const feeTypeData = () => {
    ApiCalling.apiCallBodyDataGet("fee-types/list")
      .then((res) => {
        setFeeTypeList(res.data || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    feeTypeData();
  }, []);

  useEffect(() => {
    console.log("getBillPlanList useEffect");
    let mounted = true;

    const getBillPlanList = () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("billing-plans/saved")
        .then((res) => {
          if (mounted) {
            setIsLoading(false);
            setBillPlanList(res.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    getBillPlanList();

    return () => {
      mounted = false;
    };
  }, []);

  const reset = () => {
    setName("");
    setStartDate(null);
    setPeriod("");
    setEndDate(null);
    setAmount("");
    setFeeTypeId(0);
    setSaveInLibrary(false);
    setNameError("");
    setStartDateError("");
    setPeriodError("");
    setAmountError("");
    setFeeTypeIdError("");
    setBillingPlanLibraryId(0);
    setLastPaymentDay(0);
    setLastPaymentDayError("");
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage === "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "656px",
              padding: "20px",
              position: "relative",
            }}
            role="presentation"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("Add Billing Plan")}</div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            {!isLoading ? (
              <div>
                <div className="body mt-3">
                  <div
                    className="row text-start mt-2"
                    style={{ paddingBottom: "50px" }}
                  >
                    {/* <div className="input-filed pb-2">
                      <span className="basic-text">
                        {t("Select a plan template to start with (optional)")}
                      </span>
                      <select
                        name=""
                        id=""
                        className="select-dropdown form-select  custom-input"
                        value={billingPlanLibraryId}
                        onChange={handleBillingPlanLibraryId}
                      >
                        <option>{t("Select template")}</option>
                        {billPlanList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div> */}

                    <div className="input-filed pb-2">
                      <span className="basic-text required">
                        {t("What should the billing plan be called?")}
                      </span>
                      <input
                        type="text"
                        className={`form-control ${
                          nameError.length > 0 ? "input-error" : "custom-input"
                        }`}
                        onChange={handleName}
                        value={name}
                      />
                      {nameError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                            alt="Invalid Icon"
                          />
                          {nameError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text required">
                        {t("When will first invoice be generated?")}
                      </span>

                      <DatePicker
                        ref={datepickerRef}
                        selected={startDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={handleStartDate}
                        dateFormat={school.date_format}
                        popperPlacement="top"
                        customInput={<CustomInput icon={Calender} />}
                      />

                      {startDateError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                            alt="Invalid Icon"
                          />
                          {startDateError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text required">
                        {t("How often should payers receive invoices?")}
                      </span>
                      <select
                        name=""
                        id=""
                        className={`select-dropdown  form-select ${
                          periodError.length > 0
                            ? "input-error"
                            : "custom-input"
                        }`}
                        value={period}
                        onChange={handlePeriod}
                        disabled={!startDate}
                      >
                        <option disabled value="">
                          {t("Select Period")}
                        </option>
                        {periodList?.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {periodError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                            alt="Invalid Icon"
                          />
                          {periodError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text">
                        {t("Last invoice will generate on?")}
                      </span>
                      <DatePicker
                        selected={endDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={handleEndDate}
                        dateFormat={school.date_format}
                        className="form-control custom-input"
                        popperPlacement="top"
                        disabled={!period}
                        filterDate={(d) =>
                          enabledEndDates.includes(
                            moment(d).format("YYYY-MM-DD")
                          )
                        }
                      />
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text required">
                        {t("Last payment date of invoice must be after?")}
                      </span>
                      <select
                        name=""
                        id=""
                        className={`select-dropdown form-select ${
                          lastPaymentDayError.length > 0
                            ? "input-error"
                            : "custom-input"
                        }`}
                        value={lastPaymentDay}
                        onChange={handleLastPaymentDay}
                        disabled={!startDate}
                      >
                        <option disabled value={0}>
                          {t("Select Days")}
                        </option>
                        {lastPaymentDaysList?.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {lastPaymentDayError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                            alt="Invalid Icon"
                          />
                          {lastPaymentDayError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text required">
                        {t("Select charge for this plan")}
                      </span>
                      <select
                        name=""
                        id=""
                        className={`select-dropdown form-select ${
                          feeTypeIdError.length > 0
                            ? "input-error"
                            : "custom-input"
                        }`}
                        value={feeTypeId}
                        onChange={handleFeeTypeId}
                      >
                        <option disabled value={0}>
                          {t("Select Fee Type")}
                        </option>
                        {feeTypeList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.type}
                          </option>
                        ))}
                      </select>
                      {feeTypeIdError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                            alt="Invalid Icon"
                          />
                          {feeTypeIdError}
                        </span>
                      )}
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text required">{t("Amount")}</span>
                      <input
                        type="number"
                        className={`form-control ${
                          amountError.length > 0
                            ? "input-error"
                            : "custom-input"
                        }`}
                        onChange={handleAmount}
                        value={amount}
                      />
                      {amountError.length > 0 && (
                        <span className="text-danger invalid-error">
                          <img
                            className="fa fa-exclamation-circle mr-2 invalid-svg"
                            src={Invalid}
                            alt="Invalid Icon"
                          />
                          {amountError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="border-top "
                  style={{
                    position: "fixed",
                    width: "656px",
                    bottom: "0px",
                    right: getLanguage !== "ar" && "0px",
                    background: "#ffffff",
                    padding: "10px",
                  }}
                >
                  <button
                    className="secondary-md-btn btn mr-2"
                    onClick={() => {
                      if (isStateUpdated) {
                        setIsAlertModal(true);
                      } else {
                        onClose();
                        reset();
                        setIsStateUpdated(false);
                      }
                    }}
                  >
                    {t("Cancel")}
                  </button>

                  <button
                    style={{ float: "right" }}
                    className="primary-lg-btn btn"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </Box>
        </div>

        {showNotification && (
          <ToastComponent
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            title={toastTitle}
            subtitle={toastSubtitle}
            type={toastType}
          />
        )}
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
}
export default CreateBillingPlan;
