import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import moment from "moment/moment";
import TagButton from "../../../Dashbord/TagButton";
import {
  greenTagStyle,
  orangeTagStyle,
  redTagStyle,
} from "../../../Dashbord/TagButtonStyle";
import Loader from "../../../../components/common/Loader";
import InvoiceView from "../InvoiceView";
import { loadStripe } from "@stripe/stripe-js";
import ToastHOC from "../../../HOC/ToastHOC";
import { InvoiceStatus } from "../../../../Enums";
import { useSelector } from "react-redux";

function PendingInvoices(props) {
  const { setIsToast, setToastType } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const user = Util.getUser();
  const selected = useSelector((state) => state.SelectedStudentReducer);
  const [remainingInvoices, setRemainingInvoices] = useState([]);
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [studentObjects, setStudentObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stripePublishKey, setStripePublishKey] = useState("");
  const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

  const getPublishKey = () => {
    ApiCalling.apiCallBodyDataGet(
      "stripe-key/get-key",
      selected.selectedStudent.student.School.id
    )
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          setStripePublishKey(res.data.key);
          console.log(res.data.key)
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (Util.isValidData(selected.selectedStudent.student.School.id)) {
      getPublishKey();
    }
  }, [selected.selectedStudent.student.School.id]);
  const handlePay = (id) => {
    const data = {
      invoice_id: id,
    };
    ApiCalling.apiCallBodyDataPost(
      "stripe/create-order",
      data,
      selected.selectedStudent.student.School.id
    ).then(async (res) => {
      if (res.data.session_id) {
        const sessionId = res.data.session_id;

        let stripe;
        try {
          // stripe = await loadStripe(STRIPE_PUBLISH_KEY);
          // console.log(stripe);
          stripe = await loadStripe(stripePublishKey);
        } catch (error) {
          console.log("error", error);
        }
        if (sessionId) {
          const result = await stripe
            .redirectToCheckout({
              sessionId: sessionId,
            })
            .then((res) => {
              // console.log("pay", res);
            });
        } else {
          setToastType("success");
          setIsToast(
            res.data.raw.message || res.data.message || "Payment error"
          );
        }
      } else {
        setToastType("error");
        setIsToast(res.data?.message || "Payment error");
      }
    });
  };

  const getStudentBalance = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(
      `student/balance/${selected.selectedStudent.id}`,
      selected.selectedStudent.student.School.id
    )
      .then((res) => {
        setIsLoading(false);
        setStudentObjects([res.data]);
        setRemainingInvoices(res.data.remaining_invoices);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (Util.isValidData(selected)) {
      getStudentBalance();
    }
  }, [selected]);

  return (
    <div className="mt-2">
      {!isLoading ? (
        <table
          className="table"
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr className="text-nowrap">
              <th>{t("Invoice")}</th>
              <th>{t("Fee Type")}</th>
              <th>{t("Due Date")}</th>
              <th>{t("Status")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Balance")}</th>
              <th></th>
            </tr>
          </thead>
          {remainingInvoices.length > 0 ? (
            <tbody>
              {remainingInvoices.map((r, index) => {
                const LastDate = moment(r.last_payment_date).format(
                  "MM/DD/YYYY"
                );
                const Today = moment(new Date()).format("MM/DD/YYYY");

                return (
                  <>
                    <tr key={index}>
                      <td>{Util.invoiceIdFormat(r.id)}</td>
                      <td>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {Util.getListDisplay(
                            r.types.map((f) => f.Fee_Type),
                            2,
                            "\n"
                          )}
                        </p>
                      </td>
                      <td className={`${LastDate < Today && "text-danger"}`}>
                        {Util.isValidData(r.last_payment_date) ? LastDate : "-"}
                        {LastDate < Today && (
                          <p className="small-text">
                            {moment(Today).diff(moment(LastDate), "days")}{" "}
                            {t("Days Past Due")}
                          </p>
                        )}
                      </td>
                      <td>
                        <TagButton
                          style={"btn btn-secondary"}
                          size={"150px"}
                          title={Util.getInvoiceStatus(r.status)}
                          buttonStyle={
                            r.status == 0
                              ? redTagStyle
                              : r.status == 1
                              ? orangeTagStyle
                              : greenTagStyle
                          }
                          icon="fa fa-circle"
                          iconSize="6px"
                        />
                      </td>
                      <td>{Util.amountFormat(r.total)}</td>
                      <td>{Util.invoiceFormat(r.due)}</td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-lg custom-primary "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#ffffff", padding: "5px, 10px" }}
                          >
                            {t("Action")}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setIsVisibleInvoice(true);
                                  setInvoiceId(r.id);
                                }}
                              >
                                {t("View")}
                              </a>
                              {r.status !== InvoiceStatus.PAID && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    handlePay(r.id);
                                  }}
                                >
                                  {t("Pay")}
                                </a>
                              )}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tfoot>
              <tr>
                <td colSpan={7}>{t("Not Found!")}</td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
      <InvoiceView
        show={isVisibleInvoice}
        onClose={() => {
          setIsVisibleInvoice(false);
        }}
        invoiceId={invoiceId}
        studentObject={studentObjects}
      />
    </div>
  );
}
export default ToastHOC(PendingInvoices);
