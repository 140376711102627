import { Box, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import "react-datepicker/dist/react-datepicker.css";
import ApiCalling from "../../../network/ApiCalling";
import moment from "moment/moment";
import { imageStudentList } from "../../CommonImage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExitAlert from "../../../components/ExitAlert";
import Loader from "../../../components/common/Loader";

function SetBillingPlan({
  show,
  onClose,
  onSave,
  studentIds,
  updateData,
  student,
}) {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [period, setPeriod] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [feeTypeId, setFeeTypeId] = useState(0);
  const [saveInLibrary, setSaveInLibrary] = useState(false);
  const [lastPaymentDay, setLastPaymentDay] = useState(0);
  const [billingPlanLibraryId, setBillingPlanLibraryId] = useState(0);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billPlanList, setBillPlanList] = useState([]);

  const handleBillingPlanLibraryId = (e) => {
    console.log("handleBillingPlanLibraryId function");
    console.log("e.target.value", e.target.value);
    setIsStateUpdated(true);
    setBillingPlanLibraryId(e.target.value);
    const templatePlan = billPlanList.find((obj) => {
      return obj.id === Number(e.target.value);
    });

    console.log("templatePlan", templatePlan);
    console.log("templatePlan.id", templatePlan.id);

    if (templatePlan !== undefined) {
      setId(templatePlan.id);
      setName(templatePlan.name);
      setPeriod(templatePlan.period);
      setLastPaymentDay(templatePlan.last_payment_date);
      setFeeTypeId(templatePlan.fee_type_id);
      setAmount(templatePlan.amount);
      setStartDate(templatePlan.start_date);
      setEndDate(templatePlan.end_date);
    } else {
      setId(0);
      setName("");
      setPeriod("");
      setLastPaymentDay(0);
      setFeeTypeId(0);
      setAmount("");
      setStartDate(null);
      setEndDate(null);
      setBillingPlanLibraryId(0);
    }
  };
  const handleSave = () => {
    setIsStateUpdated(false);
    let error = false;

    if (!error) {
      setIsLoading(true);
      const studentId =
        studentIds.length > 0 ? studentIds.join(",") : studentIds.toString();
      const start_date = moment(startDate).format("YYYY-MM-DD");
      const end_date = moment(endDate).format("YYYY-MM-DD");
      const data = {
        id: id,
        name: name,
        start_date: start_date,
        end_date: end_date,
        period: period,
        save_in_library: saveInLibrary,
        fee_type_id: feeTypeId,
        amount: amount,
        student_ids: studentId,
        last_payment_date: lastPaymentDay,
      };
      console.log("API data", data);
      setIsLoading(false);

      ApiCalling.apiCallBodyDataPost("billing-invoice/add", data)
        .then((res) => {
          if (res.data.status === true) {
            setIsLoading(false);
            onSave(res.data.message);
            updateData();
            reset();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    console.log("getBillPlanList useEffect");
    let mounted = true;

    const getBillPlanList = () => {
      setIsLoading(true);
      ApiCalling.apiCallBodyDataGet("billing-plans/saved")
        .then((res) => {
          if (mounted) {
            setIsLoading(false);
            setBillPlanList(res.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    getBillPlanList();

    return () => {
      mounted = false;
    };
  }, []);

  const reset = () => {
    setName("");
    setStartDate(null);
    setPeriod("");
    setEndDate(null);
    setAmount("");
    setFeeTypeId(0);
    setSaveInLibrary(false);
    setBillingPlanLibraryId(0);
    setLastPaymentDay(0);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={getLanguage === "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            onClose();
            reset();
            setIsStateUpdated(false);
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "656px",
              padding: "20px",
              position: "relative",
            }}
            role="presentation"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">{t("Add Billing Plan")}</div>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    onClose();
                    reset();
                    setIsStateUpdated(false);
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            {!isLoading ? (
              <div>
                <div className="body mt-3">
                  <div
                    className="row text-start mt-2"
                    style={{ paddingBottom: "50px" }}
                  >
                    <div className="mt-2 pb-2">
                      <div>
                        {student.length} {t("Student(s) selected")}
                      </div>
                      {Util.isValidArray(student) &&
                        student.map((s, index) => {
                          return (
                            <span
                              className="badge badge-students mt-2"
                              key={index}
                            >
                              <img
                                className="rounded-circle mr-2"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={
                                  s.photo
                                    ? imageStudentList + s.photo
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                }
                                alt=""
                              />
                              {s.first_name + " " + s.last_name}
                            </span>
                          );
                        })}
                    </div>

                    <div className="input-filed pb-2">
                      <span className="basic-text required">
                        {t("Select a plan template to start with")}
                      </span>
                      <select
                        name=""
                        id=""
                        className="select-dropdown form-select  custom-input"
                        value={billingPlanLibraryId}
                        onChange={handleBillingPlanLibraryId}
                      >
                        <option value={0}>{t("Select template")}</option>
                        {billPlanList?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="border-top "
                  style={{
                    position: "fixed",
                    width: "656px",
                    bottom: "0px",
                    right: getLanguage !== "ar" && "0px",
                    background: "#ffffff",
                    padding: "10px",
                  }}
                >
                  <button
                    className="secondary-md-btn btn mr-2"
                    onClick={() => {
                      if (isStateUpdated) {
                        setIsAlertModal(true);
                      } else {
                        onClose();
                        reset();
                        setIsStateUpdated(false);
                      }
                    }}
                  >
                    {t("Cancel")}
                  </button>

                  {billingPlanLibraryId > 0 && (
                    <button
                      style={{ float: "right" }}
                      className="primary-lg-btn btn"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {t("Save")}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </Box>
        </div>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
}
export default SetBillingPlan;
