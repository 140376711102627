import React, { useEffect, useState } from "react";
import PendingInvoices from "./PendingInvoices";
import PendingCredits from "./PendingCredits";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import "../../Dashbord/DashboardCustom.css";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import InvoiceCard, { CardDetail } from "./InvoiceCard/InvoiceCard";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";

function BillDashboard(props) {
  const { t } = useTranslation();
  const { setIsToast, setToastType } = props;
  const school = Util.getSelectedSchool();
  const schoolId = school?.id;
  const [value, setValue] = React.useState("Open Invoices");
  const [depositList, setDepositList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceCount, setInvoiceCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SchoolDeposit = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`payment/school-deposit-credit/${schoolId}`)
      .then((res) => {
        setIsLoading(false);
        setDepositList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    SchoolDeposit();
  }, []);

  return (
    <div>
      <div className="row py-3">
        <div className="col-md-8"></div>
      </div>
      {/* <div className={"table-div"}> */}
      {!isLoading ? (
        <div>
          <div className="row m-b-30">
            <div className="col-lg-4 col-md-6">
              <InvoiceCard
                header={`${t("Total Pending Invoice")}
                ${
                  Util.isValidData(depositList?.dues?.pending_count) &&
                  `(${depositList?.dues?.pending_count})`
                }`}
                amount={
                  depositList?.dues?.total_due !== undefined
                    ? Util.amountFormat(depositList?.dues?.total_due)
                    : t("N/A")
                }
                amounts={[
                  {
                    amount: depositList?.dues?.partial_paid || 0,
                    color: "#EAA12E",
                  },
                  {
                    amount: depositList?.dues?.unpaid || 0,
                    color: "#DC2626",
                  },
                ]}
                contentColor={"#DC2626"}
              >
                <CardDetail
                  title={`${t("Partial Paid")}
                ${
                  Util.isValidData(depositList?.dues?.partial_paid_count) &&
                  `(${depositList?.dues?.partial_paid_count})`
                }`}
                  amount={
                    depositList?.dues?.partial_paid !== undefined
                      ? Util.amountFormat(depositList?.dues?.partial_paid)
                      : t("N/A")
                  }
                  circle={"#EAA12E"}
                  textColor={"#EAA12E"}
                />
                <CardDetail
                  title={` ${t("Unpaid")}
                ${
                  Util.isValidData(depositList?.dues?.unpaid_count) &&
                  `(${depositList?.dues?.unpaid_count})`
                }`}
                  amount={
                    depositList?.dues?.unpaid !== undefined
                      ? Util.amountFormat(depositList?.dues?.unpaid)
                      : t("N/A")
                  }
                  circle={"#DC2626"}
                  textColor={"#DC2626"}
                />
              </InvoiceCard>
            </div>
            <div className="col-lg-4 col-md-6">
              <InvoiceCard
                header={` ${t("Payments in last 35 days")}
                ${
                  Util.isValidData(depositList?.payments?.total_count) &&
                  `(${depositList?.payments?.total_count})`
                }`}
                amount={
                  depositList?.payments?.total_amount !== undefined
                    ? Util.amountFormat(depositList?.payments?.total_amount)
                    : t("N/A")
                }
                amounts={[
                  {
                    amount: depositList?.payments?.offline || 0,
                    color: "#117F39",
                  },
                  {
                    amount: depositList?.payments?.online || 0,
                    color: "#15A84B",
                  },
                  // {
                  //   amount: depositList?.payments?.bank || 0,
                  //   color: "#19D35D",
                  // },
                ]}
                contentColor={"#117F39"}
              >
                <CardDetail
                  title={`${t("Cash")}
                ${
                  Util.isValidData(depositList?.payments?.offline_count) &&
                  `(${depositList?.payments?.offline_count})`
                }`}
                  amount={
                    depositList?.payments?.offline !== undefined
                      ? Util.amountFormat(depositList?.payments?.offline)
                      : t("N/A")
                  }
                  circle={"#117F39"}
                  textColor={"#117F39"}
                />
                <CardDetail
                  title={`${t("Online")}
                ${
                  Util.isValidData(depositList?.payments?.online_count) &&
                  `(${depositList?.payments?.online_count})`
                }`}
                  amount={
                    depositList?.payments?.online !== undefined
                      ? Util.amountFormat(depositList?.payments?.online)
                      : t("N/A")
                  }
                  circle={"#15A84B"}
                  textColor={"#15A84B"}
                />
                {/* <CardDetail
                  title={t("Bank Channel")}
                  amount={"00.00"}
                  circle={"#19D35D"}
                  textColor={"#19D35D"}
                /> */}
              </InvoiceCard>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#6418c3",
                  height: 2,
                },
              }}
            >
              <Tab
                sx={{
                  fontFamily: "Public Sans, sans-serif",
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "#6418c3",
                    fontWeight: "600",
                  },
                  "&.Mui-selected .css-11yukd5-MuiTabs-indicatorr": {
                    backgroundColor: "#6418c3",
                  },
                }}
                value="Open Invoices"
                label={`${t("Open Invoices")} (${invoiceCount})`}
              />
              <Tab
                sx={{
                  fontFamily: "Public Sans, sans-serif",
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "#6418c3",
                    fontWeight: "600",
                  },
                  "&.Mui-selected .MuiTabs-indicator": {
                    backgroundColor: "#6418c3",
                  },
                }}
                value="Available Credits & Payments"
                label={t("Available Credits & Payments")}
              />
            </Tabs>
          </Box>
          {value === "Open Invoices" && (
            <PendingInvoices
              InvoiceCount={(n) => {
                setInvoiceCount(n);
              }}
              message={(msg, type = "success") => {
                setToastType(type);
                setIsToast(msg);
              }}
            />
          )}
          {value === "Available Credits & Payments" && <PendingCredits />}
        </div>
      ) : (
        <Loader />
      )}
      {/* </div> */}
    </div>
  );
}
export default ToastHOC(BillDashboard);
