import React, { useContext, useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { googleLogout } from "@react-oauth/google";
import Util from "../Util";
import Add from "../assets/Icon/add.svg";
import Edit from "../assets/Icon/Edit.svg";
import Setting from "../assets/Icon/setting.svg";
import activeIndicator from "../assets/Icon/radio-selected.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Searchwhite from "../assets/Icon/Search-white.svg";
import Search from "../assets/Icon/Search.svg";
import notificationWhite from "../assets/Icon/notification-unread-white.svg";
import notification from "../assets/Icon/notification-unread.svg";
import { SocketContext } from "../context/SocketContext";
import UserComponent from "./UserComponent";
import BuildingSchool from "../assets/Icon/building-school.svg";
import Select from "react-dropdown-select";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SchoolSetting from "./SchoolDetails/SchoolSetting";
import { switchSchool } from "../actions/SwitchSchoolAction";
import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "../screens/Dashbord/NotificationModal";
import SearchModal from "../screens/Dashbord/SearchModal";
import ModalComponent from "../screens/Dashbord/ModalComponent";
import AddSchool from "./SchoolDetails/AddSchool";
import ToastHOC from "../screens/HOC/ToastHOC";
import { Toggle } from "../actions/ToggleAction";
import ApiCalling from "../network/ApiCalling";

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;
function Topbar(props) {
  const { setIsToast, setIsSubToast } = props;
  const user = Util.getUser();
  const history = useHistory();
  const socket = useContext(SocketContext);
  const getLanguage = Util.getSelectedLanguage();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState([]);

  const [showSwitchSchoolModal, setShowSwitchSchoolModal] = useState(false);
  const [selectedSchoolName, setSelectedSchoolName] = useState("");
  const [getSchool, setGetSchool] = useState(null);
  const [showAddSchoolPopup, setShowAddSchoolPopup] = useState(false);
  const [showSchoolSettingPopup, setShowSchoolSettingPopup] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [confirmParentLogin, setConfirmParentLogin] = useState(false);
  const [getUser, setGetUser] = useState(user)
  // const [isOpen, setIsOpen] = useState(false);

  const isShow = useSelector((state) => state.ToggleReducer);
  const isUpdate = useSelector((state) => state.impersonateUser);
  const dropdownRef = useRef(null);

  const tabletSidebar = () => {
    const leftSidebar = document.getElementById("left-sidebar");

    if (getLanguage === "ar") {
      if (leftSidebar.style.right === "-260px") {
        leftSidebar.style.right = "0";

        if (toggleMenu) {
          document.body.classList.remove("layout-fullwidth");
        } else {
          document.body.classList.add("layout-fullwidth");
        }
      }
    } else {
      if (leftSidebar.style.left === "-260px") {
        leftSidebar.style.left = "0";

        if (toggleMenu) {
          document.body.classList.remove("layout-fullwidth");
        } else {
          document.body.classList.add("layout-fullwidth");
        }
      }
    }
    setToggleMenu(!toggleMenu);
    dispatch(Toggle(false));
  };
  const mainSidebar = () => {
    if (getLanguage === "ar") {
      document.getElementById("left-sidebar").style.right = "0";
    } else {
      document.getElementById("left-sidebar").style.left = "0";
    }
    let origionalSize = "100%";
    let reduceSize = "260px";
    let size = `calc(${origionalSize} - ${reduceSize})`;
    document.getElementById("main-content").style.width = size;
    dispatch(Toggle(false));
  };
  useEffect(() => {
    if (getLanguage === "ar") {
      document.getElementById("main-content").style.float = "left";
    } else {
      document.getElementById("main-content").style.float = "right";
    }
  }, [getLanguage]);

  const customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <StyledItem>
      <div
        onClick={() => {
          handleSchoolDropdown(item);
          // if (methods && typeof methods.dropDown === "function") {
          //   console.log("dropdownRenderer",methods.dropDown(true))
          //   methods.dropDown(true);
          // }
        }}
        className={item.icon === Add ? "option-seperator" : ""}
      >
        <div className="d-flex justify-content-between align-items-center ml-1">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <img src={item.icon} />
            {item.name}
          </div>

          {item.id == Util.getSelectedSchool()?.id ? (
            <img src={activeIndicator} height={20} width={20} />
          ) : (
            ""
          )}
        </div>
      </div>
    </StyledItem>
  );
  const handleSchoolDropdown = (selectedOption) => {
    const selectedId = selectedOption.id;
    setSelectedSchoolName(selectedOption.name);
    const getSchoolID = Util.getSelectedSchool();

    if (selectedId > 0) {
      const selectedSchool = schoolNameList.find(
        (school) =>
          school.school.id === selectedId && school.school.id != getSchoolID.id
      );

      if (selectedSchool) {
        setGetSchool(selectedSchool.school);
        setShowSwitchSchoolModal(true);
      }
      // else {
      //   if (selectedId === getSchool.id) {
      //     this.props.history.push("/school-details");
      //   }
      // }
    } else {
      if (selectedId === -1) {
        setShowAddSchoolPopup(true);
      } else if (selectedId === -2) {
        setShowSchoolSettingPopup(true);
      } else if (selectedId === -3) {
        return history.push("/school-details");
      }
    }
  };

  const handleSwitchSchool = async () => {
    setShowSwitchSchoolModal(false);
    await Util.removeUser();
    await Util.saveUser(user);
    await Util.saveParentMode(false);
    await Util.saveSelectedSchool(getSchool);
    dispatch(switchSchool(false));
    history.push("/Dashboard");
  };
  const loginAsParent = async () => {
    const student = user.Parents[0].Student;
    Util.removeSelectedSchool();
    Util.removeRoleInSelectedSchool();
    Util.removeSelectedAcademicYear();
    Util.saveParentMode(true);
    Util.saveSelectedStudent(student);
    setConfirmParentLogin(false);
    history.push("/ParentsDashboard");
  };
  const makeUserOffline = async () => {
    if (Util.isValidData(user)) {
      socket.emit("offline", user?.id);
    }
  };
  const handleBlur = async () => {
    //console.log("onBlur ***************");
    await makeUserOffline();
  };

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    setSchoolNameList(
      user.user_Schools.filter(
        (userSchool) => userSchool?.SchoolId && userSchool.school !== null
      )
    );
    const cleanup = async () => {
      await makeUserOffline();
      window.removeEventListener("blur", handleBlur);
    };

    cleanup();
    return () => {};
  }, []);
  const options = [
    ...schoolNameList.map((school) => ({
      id: school.school?.id,
      name: school.school?.name,
      icon: BuildingSchool,
    })),
    Util.hasPermission("SCHOOL_ADD") && {
      id: -1,
      name: "Add New School",
      icon: Add,
    },
    { id: -3, name: "School Profile", icon: Edit },
    { id: -2, name: "School Settings", icon: Setting },
  ].filter(Boolean);
 
  const getUserData = () => {
    ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
      .then((res) => {
        setGetUser(res.data);
        Util.saveUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() =>{
    if(isUpdate)
    {
     getUserData();
    }
  },[isUpdate])

  return (
    <div
      className=" header-border  sticky-top topbar"
      style={{ height: Util.getUser()?.is_impersonated && Util.getUserImpersonate() ? "90px" : "" }}
    >
      <div>
        {Util.getUser()?.is_impersonated && Util.getUserImpersonate() ? (
          <div className="d-flex justify-content-center cust-account">
            <span>Customer Account</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="top-header">
        <div
          className={`d-flex justify-content-between align-items-center ${
            getLanguage == "ar" ? "ml-3" : "mr-3"
          }`}
        >
          {isShow.Clicked == true && (
            <div id="barIcon">
              <IconButton onClick={mainSidebar} className="collops-main mt-2">
                <svg
                  id="cu3-icon-sidebarToggle"
                  viewBox="0 0 24 24"
                  fill="#6c767d"
                  width={24}
                  height={24}
                >
                  <path
                    fillRule="evenodd"
                    d="M5 4.83A1.17 1.17 0 0 0 3.83 6v12c0 .646.524 1.17 1.17 1.17h5.17V4.83H5Zm6.83 0v14.34H19A1.17 1.17 0 0 0 20.17 18V6A1.17 1.17 0 0 0 19 4.83h-7.17ZM2.17 6A2.83 2.83 0 0 1 5 3.17h14A2.83 2.83 0 0 1 21.83 6v12A2.83 2.83 0 0 1 19 20.83H5A2.83 2.83 0 0 1 2.17 18V6Zm3 2A.83.83 0 0 1 6 7.17h2a.83.83 0 0 1 0 1.66H6A.83.83 0 0 1 5.17 8Zm0 2.5A.83.83 0 0 1 6 9.67h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Zm0 2.5a.83.83 0 0 1 .83-.83h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </IconButton>

              <IconButton onClick={mainSidebar} className="collops-tablet mt-2">
                <svg
                  id="cu3-icon-sidebarToggle"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width={24}
                  height={24}
                >
                  <path
                    fillRule="evenodd"
                    d="M5 4.83A1.17 1.17 0 0 0 3.83 6v12c0 .646.524 1.17 1.17 1.17h5.17V4.83H5Zm6.83 0v14.34H19A1.17 1.17 0 0 0 20.17 18V6A1.17 1.17 0 0 0 19 4.83h-7.17ZM2.17 6A2.83 2.83 0 0 1 5 3.17h14A2.83 2.83 0 0 1 21.83 6v12A2.83 2.83 0 0 1 19 20.83H5A2.83 2.83 0 0 1 2.17 18V6Zm3 2A.83.83 0 0 1 6 7.17h2a.83.83 0 0 1 0 1.66H6A.83.83 0 0 1 5.17 8Zm0 2.5A.83.83 0 0 1 6 9.67h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Zm0 2.5a.83.83 0 0 1 .83-.83h2a.83.83 0 1 1 0 1.66H6a.83.83 0 0 1-.83-.83Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </IconButton>
            </div>
          )}

          <div id="navbar-search" className="mt-3 ml-3">
            <Select
              className="select-custom select-school"
              options={options}
              searchable={false}
              placeholder={Util.getSelectedSchool()?.name}
              valueField="id"
              itemRenderer={customItemRenderer}
              closeOnSelect={true}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center gap-2 mt-2">
            <a
              className={"icon-menu d-none d-sm-block mr-3"}
              onClick={() => {
                setShowSearch(true);
              }}
            >
              {showSearch ? (
                <div className="search-active" style={{ top: 0, left: 0 }}>
                  <img
                    src={Searchwhite}
                    alt="Search"
                    width={"22px"}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Search}
                    alt="Search"
                    className="img-responsive logo svg-icon"
                    width={"24px"}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
            </a>
            <a
              className={`dropdown-toggle icon-menu ${
                showNotification && "notification-circle"
              }`}
              style={
                (!showNotification && {
                  width: 30,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }) ||
                {}
              }
              onClick={() => {
                setShowNotification(true);
              }}
            >
              {showNotification ? (
                <img
                  src={notificationWhite}
                  width={"20px"}
                  className="img-responsive logo svg-icon"
                  style={{ marginLeft: "6px", marginTop: "6px" }}
                />
              ) : (
                <img
                  src={notification}
                  alt="notification"
                  width={"24px"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </a>
            <span className="divider"></span>
            <UserComponent
              LoginAsParent={() => {
                setConfirmParentLogin(true);
              }}
              onLogoutClick={(e) => {
                e.preventDefault();
                history.push("/");
                makeUserOffline();
                Util.removeUser();
                localStorage.clear();
                googleLogout();
              }}
            />
          </div>
        </div>
      </div>
      {showSchoolSettingPopup == true && (
        <SchoolSetting
          isVisible={showSchoolSettingPopup}
          isClose={() => {
            setShowSchoolSettingPopup(false);
          }}
          notification={(msg) => {
            if (msg) {
              setIsToast(msg);
              setIsSubToast("You have successfully updated a school setting");
            }
          }}
        />
      )}

      {showAddSchoolPopup == true && (
        <AddSchool
          props={props}
          isSave={(school) => {
            // this.setState({ addSchool: school });
          }}
          isVisible={showAddSchoolPopup}
          isClose={() => {
            setShowAddSchoolPopup(false);
          }}
          notification={(msg) => {
            if (msg) {
              setIsToast(msg);
              setIsSubToast("You have successfully added a New School");
            }
          }}
          updateSchoolList={(list) => {
            setSchoolNameList(
              list.userSchools.filter(
                (userSchool) =>
                  userSchool?.SchoolId && userSchool.school !== null
              )
            );
          }}
        />
      )}
      {showSwitchSchoolModal == true && (
        <ModalComponent
          show={showSwitchSchoolModal}
          onHide={() => {
            setShowSwitchSchoolModal(false);
          }}
          title={"Switch School"}
          subTitle={
            "You will be redirected to the" +
            " " +
            selectedSchoolName +
            " " +
            "after logout from this school"
          }
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setShowSwitchSchoolModal(false);
          }}
          onConfirmClick={() => {
            handleSwitchSchool();
          }}
        />
      )}
      {confirmParentLogin == true && (
        <ModalComponent
          show={confirmParentLogin}
          onHide={() => {
            setConfirmParentLogin(false);
          }}
          title={"Switch As Parent"}
          size="sm"
          onCancel="Cancel"
          onConfirm="Confirm"
          onCancelClick={() => {
            setConfirmParentLogin(false);
          }}
          onConfirmClick={loginAsParent}
        >
          <span>
            {t("Are you sure to Logout from this School")}?
            <br /> {t("You will be redirected to the ")}{" "}
            <b>{t("Parent Dashboard")}</b> {t("after logout from this school")}.
          </span>
        </ModalComponent>
      )}
      {showSearch == true && (
        <SearchModal
          show={showSearch}
          onHide={() => {
            setShowSearch(false);
          }}
        />
      )}
      {showNotification == true && (
        <NotificationModal
          show={showNotification}
          onHide={() => {
            setShowNotification(false);
          }}
        />
      )}
    </div>
  );
}
export default ToastHOC(Topbar);
