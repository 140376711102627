import React, { useCallback, useEffect, useState } from "react";
import sampleFile from "../../../assets/File/sample.xlsx";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import Util from "../../../Util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import Loader from "../../../components/common/Loader";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import Download from "../../../../src/assets/Icon/download.jpg";
import CustomFileUpload from "../CustomFileUpload";
import Call from "../../../../src/assets/Icon/call.svg";
import moment from "moment/moment";
import Calander from "../../../../src/assets/Icon/calendar.svg";
import Sort from "../../../../src/assets/Icon/Short.svg";
import Invalid from "../../../assets/Icon/invalid.svg";
import ExitAlert from "../../../components/ExitAlert";
import { format, parse, parseISO } from "date-fns";
import Select from "react-dropdown-select";
import { Tooltip } from "@mui/material";

function BulkStudent(props) {
  const { t } = useTranslation();
  const { setIsToast } = props;
  const hasRoom = props.location.hasClassRoom;
  const school = Util.getSelectedSchool();
  const pendingToast = props.location?.pendingToast;
  const history = useHistory();

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [room, setRoom] = useState(null);
  const [roomError, setRoomError] = useState("");
  const [gradeId, setGradeId] = useState(0);
  const [gradeError, setGradeError] = useState("");
  const [classId, setClassId] = useState(0);
  const [classError, setClassError] = useState("");
  const [stageList, setStageList] = useState([]);
  const [showFileData, setShowFileData] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [allStateList, setAllStateList] = useState([]);
  const [gradeLists, setGradeLists] = useState([]);
  const [allCityList, setAllCityList] = useState([]);
  const [classroomList, setClassroomList] = useState([]);
  const [timingList, setTimingList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([
    {
      id: 1,
      childs_first_name: "",
      childs_last_name: "",
      childs_phone_number: "",
      dob: null,
      // address1: "",
      // address2: "",
      // pinCode: "",

      country: "",
      country_id: 0,
      state: "",
      state_id: 0,
      city: "",
      city_id: 0,
      nationality: "",
      nationality_id: 0,

      // birth_place: "",
      // birth_place_id: 0,

      level_id: 0,
      level: "",
      grade: "",
      grade_id: 0,
      classroom: "",
      classroom_id: 0,

      timing: "",
      timing_id: 0,
      days_per_week: [],
      terms: "",
      terms_id: 0,

      primary_parent_first_name: "",
      primary_parent_last_name: "",
      primary_parent_email: "",
      primary_parent_phone_number: "",
      primary_parent_relation: "",
      primary_parent_relation_id: 0,

      secondary_parent_first_name: "",
      secondary_parent_last_name: "",
      secondary_parent_email: "",
      secondary_parent_phone_number: "",
      secondary_parent_relation: "",
      secondary_parent_relation_id: 0,
    },
  ]);
  const [fieldError, setFieldError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditFields, setIsEditFields] = useState(false);

  const [documentFileName, setDocumentFileName] = useState("");
  // const [hasClassRoom, setHasClassRoom] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [alertModal, setIsAlertModal] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [countryName, setCountryName] = useState([]);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [StateName, setStateName] = useState([]);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [isStatesUpdated, setIsStatesUpdated] = useState(false);
  const [isStateCall, setIsStateCall] = useState(true);
  const [isCitiesUpdated, setIsCitiesUpdated] = useState(false);
  const [isCityCall, setIsCityCall] = useState(true);

  const gradeList = stageList
    .map((val) => {
      return val.Grades.map((grade) => {
        return {
          id: grade?.id,
          value: grade?.title,
        };
      });
    })
    .flat();

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  const sortedFields = fields.slice().sort((a, b) => {
    if (!sortConfig.key) {
      return 0;
    }

    const valueA = a[sortConfig.key];
    const valueB = b[sortConfig.key];

    if (typeof valueA === "string" && typeof valueB === "string") {
      if (sortConfig.direction === "ascending") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      return 0;
    }
  });
  const handleEditClick = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].isEditing = true;
    setFields(updatedFields);
  };
  const handleCancelClick = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].isEditing = false;
    setFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((field, i) => i !== index));
  };

  const handleDocumentDragOver = (e) => {
    e.preventDefault();
  };

  const handleDocumentDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleDocumentChange(file);
  };

  const handleDocumentChange = (file) => {
    if (file) {
      const maxSize = 50 * 1024 * 1024;
      if (file.size <= maxSize) {
        setFile(file);
        setFileURL(URL.createObjectURL(file));
      } else {
        setIsToast(t("File size exceeds the limit of 50MB"));
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleDocumentChange(file);
    setFileError("");
    setIsStateUpdated(true);
  };

  const handleInputChange = (index, event) => {
    console.log(index, event);
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
    setIsStateUpdated(true);
  };

  const CustomInput = React.forwardRef(({ value, onClick, data }, ref) => (
    <div
      className=" d-flex position-relative"
      style={{ marginTop: "-54px" }}
      onClick={onClick}
    >
      <input
        ref={ref}
        className={`custom-input__field ${
          fieldError && data == null && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img
          src={Calander}
          alt="Calander"
          className="svg-icon position-absolute"
          style={{ top: "10px", right: "10px" }}
        />
      </div>
    </div>
  ));

  const handleSelectCountryChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["country_id"] = selected.id;
      newFields[index]["country"] = selected.name;

      newFields[index]["state_id"] = 0;
      newFields[index]["state"] = "";

      newFields[index]["city_id"] = 0;
      newFields[index]["city"] = "";
      setFields(newFields);
    }

    if (selected) {
      setIsStateCall(false);
      setCountryName([selected.name]);
    }

    setIsStateUpdated(true);
  };

  const handleSelectBirthPlaceChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["birth_place_id"] = selected.id;
      newFields[index]["birth_place"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };

  const handleSelectNationalityChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = countryList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["nationality_id"] = selected.id;
      newFields[index]["nationality"] = selected.nationality;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };

  const handleSelectTimingChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = timingList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["timing_id"] = selected.id;
      newFields[index]["timing"] = selected.title;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };

  const handleSelectTermsChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = termList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["terms_id"] = selected.id;
      newFields[index]["terms"] = selected.title;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };

  const handleSelectRelationChange = (index, event) => {
    const newFields = [...fields];
    const selectedId = event.target.value;

    const selected = relationList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["relation_id"] = selected.id;
      newFields[index]["relation"] = selected.name;
      setFields(newFields);
    }
    setIsStateUpdated(true);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    return string.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  };

  const handleSelectStateChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selectedCountry = newFields[index]?.country;
    if (allStateList && selectedCountry) {
      const selectedState = allStateList.find(
        (state) => state.id === Number(selectedId)
      );

      if (selectedState) {
        newFields[index]["state_id"] = selectedState.id;
        newFields[index]["state"] = selectedState.name;

        newFields[index]["city_id"] = 0;
        newFields[index]["city"] = "";
        // console.log("newFields", newFields);

        setFields(newFields);
      }

      if (selectedState.name) {
        setIsCityCall(false);
        setStateName([selectedState.name]);
      }

      setIsStateUpdated(true);
    }
  };

  const handleSelectCityChange = (index, values) => {
    console.log("values state", values);
    const newFields = [...fields];
    const selectedId = values[0].id;
    console.log("selectedId", selectedId);

    const selectedState = newFields[index]?.state;
    if (allCityList && selectedState) {
      const selectedCity = allCityList.find(
        (city) => city.id === Number(selectedId)
      );
      if (selectedCity) {
        newFields[index]["city_id"] = selectedCity.id;
        newFields[index]["city"] = selectedCity.name;
        setFields(newFields);
      }
    }

    setIsStateUpdated(true);
  };

  const handleDayCheckboxChange = (day, index) => {
    const updatedFields = [...fields];
    const daysPerWeek = updatedFields[index].days_per_week || [];

    if (daysPerWeek.includes(day)) {
      updatedFields[index].days_per_week = daysPerWeek.filter((d) => d !== day);
    } else {
      updatedFields[index].days_per_week = [...daysPerWeek, day];
    }

    setFields(updatedFields);
    setIsStateUpdated(true);
  };

  const ExcelDateToJSDate = (serial) => {
    const parsedDate = parse(serial, "dd-MM-yyyy", new Date());

    return parsedDate;
  };

  // const handleSelectClassRoomChange = (index, event) => {
  //   const newFields = [...fields];
  //   newFields[index]["classroom_id"] = event.target.value;
  //   newFields[index][event.target.name] = event.target.value;
  //   setFields(newFields);
  //   console.log("new", newFields);
  // };
  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  //   setFileError("");
  // };
  const handleClassChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = classroomList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["classroom_id"] = selected.id;
      newFields[index]["classroom"] = selected.name;

      setFields(newFields);
    }

    setIsStateUpdated(true);
  };

  const handleGradeChange = (index, values) => {
    const newFields = [...fields];
    const selectedId = values[0].id;
    // console.log(index, values);
    const selected = gradeList.find((r) => r.id === Number(selectedId));
    // console.log(selected, gradeList);
    if (selected) {
      newFields[index]["grade_id"] = selected.id;
      newFields[index]["grade"] = selected.value;

      newFields[index]["classroom_id"] = 0;
      newFields[index]["classroom"] = "";

      setFields(newFields);
      const classroomList = stageList
        .map((stage) => stage.Grades)
        ?.flat()
        ?.find((grade) => grade.id === selected.id)?.ClassRooms;
      setClassroomList(classroomList);
    }

    setIsStateUpdated(true);
  };

  const handleStageChange = (index, values) => {
    // console.log(index, values);
    const newFields = [...fields];
    const selectedId = values[0].id;

    const selected = stageList.find((r) => r.id === Number(selectedId));

    if (selected) {
      newFields[index]["level_id"] = selected.id;
      newFields[index]["level"] = selected.stage;

      newFields[index]["grade_id"] = 0;
      newFields[index]["grade"] = "";

      setFields(newFields);
      // console.log("*", newFields);
      const gradeList = stageList
        .map((stage) => stage)
        ?.flat()
        ?.find((grade) => grade.id === selected.id)?.Grades;
      // console.log(gradeList);
      setGradeLists(gradeList);
      // setStageList(gradeList)
    }

    setIsStateUpdated(true);
  };

  const Stagelist = async () => {
    ApiCalling.apiCallBodyDataGet("stage/list")
      .then((res) => {
        setStageList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Timinglist = async () => {
    ApiCalling.apiCallBodyDataGet("timing/list")
      .then((res) => {
        setTimingList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Termslist = async () => {
    ApiCalling.apiCallBodyDataGet("terms/school")
      .then((res) => {
        setTermList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRelationList = async () => {
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setRelationList(res.data);
    });
  };
  const countrylist = async () => {
    ApiCalling.apiCallBodyDataGet("country/list").then((res) => {
      setCountryList(res.data);
    });
  };

  const getStateList = () => {
    setIsStateLoading(true);
    setIsStatesUpdated(false);
    const data = {
      countries: countryName,
    };
    return new Promise((resolve, reject) => {
      ApiCalling.apiCallBodyDataPost(`state/by-country`, data)
        .then((res) => {
          setIsStateLoading(false);
          if (Array.isArray(res.data)) {
            setAllStateList(res.data);
          }
          if (isStateCall) {
            setIsStatesUpdated(true);
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCityList = () => {
    setIsCityLoading(true);
    setIsCitiesUpdated(false);
    const data = {
      states: StateName,
    };
    return new Promise((resolve, reject) => {
      ApiCalling.apiCallBodyDataPost(`city/by-state`, data)
        .then((res) => {
          setIsCityLoading(false);
          if (Array.isArray(res.data)) {
            setAllCityList(res.data);
          }
          if (isCityCall) {
            setIsCitiesUpdated(true);
          }
          resolve();
        })
        .catch((error) => [reject(error)]);
    });
  };

  const days = [
    { value: "M", name: "Monday" },
    { value: "TU", name: "Tuesday" },
    { value: "W", name: "Wednesday" },
    { value: "TH", name: "Thursday" },
    { value: "F", name: "Friday" },
  ];

  const readFileDetails = (data) => {
    const countryNames = [];
    const stateNames = [];
    data.forEach((element) => {
      const country = element.Country || element.country;
      const state = element.State || element.state;

      if (country && country !== null && country !== undefined) {
        countryNames.push(country);
      }
      if (state && state !== null && state !== undefined) {
        stateNames.push(state);
      }
    });
    if (countryNames.length > 0) {
      setCountryName(countryNames);
    } else {
      // console.log("updated")
      setIsStatesUpdated(true);
    }
    if (stateNames.length > 0) {
      setStateName(stateNames);
    } else {
      setIsCitiesUpdated(true);
    }
  };

  const handleFileRead = async () => {
    setFileError("");
    setRoomError("");
    setGradeError("");
    setClassError("");
    setIsLoading(true);
    let error = false;
    if (file == "") {
      setFileError(t("please select file"));
      error = true;
    }
    if (!error) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const fileData = XLSX.utils.sheet_to_json(firstSheet);
        await setFileDetails(fileData);
        await readFileDetails(fileData);
        setIsStateCall(true);
        setIsCityCall(true);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (isStatesUpdated && isCitiesUpdated) {
      if (fileDetails !== null) {
        processData(fileDetails);
      }
    }
  }, [isStatesUpdated, allStateList, isCitiesUpdated]);

  const processData = (fileData) => {
    // console.log("PROCESS");
    var temp = [];
    var i = 0;

    fileData.forEach((element) => {
      const newElement = {};
      for (const key in element) {
        if (key.toLowerCase().startsWith("dob")) {
          newElement.dob = element[key];
        } else {
          newElement[key.toLowerCase()] = element[key];
        }
      }
      var birthPlaceId = 0;
      var nationalityId = 0;
      var countryId = 0;
      var stateId = 0;
      var cityId = 0;
      var primaryrelationId = 0;
      var secondaryrelationId = 0;
      var stageId = 0;
      var gradeId = 0;
      var classroomId = 0;
      var timingId = 0;
      var termId = 0;

      const daysFromFile = newElement.days_per_week;

      countryList.forEach((country) => {
        if (
          country.name.toLowerCase() === newElement?.birth_place?.toLowerCase()
        ) {
          birthPlaceId = country.id;
        }
        if (
          country.nationality?.toLowerCase() ===
          newElement?.nationality?.toLowerCase()
        ) {
          nationalityId = country.id;
        }
        if (country.name.toLowerCase() === newElement?.country?.toLowerCase()) {
          countryId = country.id;
        }
      });

      allStateList.forEach((state) => {
        if (state.country_id === countryId) {
          if (state.name.toLowerCase() === newElement?.state?.toLowerCase()) {
            stateId = state.id;
            return;
          }
        }
      });

      allCityList.forEach((city) => {
        if (city.state_id === stateId) {
          if (city.name.toLowerCase() === newElement?.city?.toLowerCase()) {
            cityId = city.id;
          }
        }
      });

      relationList.map((c) => {
        if (
          c.type.toLowerCase() ===
          newElement?.primary_parent_relation?.toLowerCase()
        ) {
          primaryrelationId = c.id;
        }
      });
      relationList.map((c) => {
        if (
          c.type.toLowerCase() ===
          newElement?.secondary_parent_relation?.toLowerCase()
        ) {
          secondaryrelationId = c.id;
        }
      });
      const parseDaysString = (daysString) => {
        if (daysString && daysString.trim() !== "") {
          return daysString.split(",").map((day) => day.trim());
        }
        return [];
      };

      const daysArray = parseDaysString(daysFromFile);
      const jsDate = ExcelDateToJSDate(newElement.dob);

      stageList.map((stage) => {
        if (
          stage.stage?.toString().toLowerCase() ===
          newElement?.level?.toString().toLowerCase()
        ) {
          stageId = stage.id;
        }
      });

      gradeList.map((grade) => {
        if (
          grade.value?.toString().toLowerCase() ===
          newElement?.grade?.toString().toLowerCase()
        ) {
          gradeId = grade.id;
          const classroomList = stageList
            .map((stage) => stage.Grades)
            ?.flat()
            ?.find((gr) => gr.id === grade.id)?.ClassRooms;
          setClassroomList(classroomList);
        }
      });

      const currentClassroomList = stageList
        .map((stage) => stage.Grades)
        ?.flat()
        ?.find((gr) => gr.id === gradeId)?.ClassRooms;
      currentClassroomList &&
        currentClassroomList.length &&
        currentClassroomList.map((classroom) => {
          if (
            classroom.name?.toString().toLowerCase() ===
            newElement?.classroom?.toString().toLowerCase()
          ) {
            classroomId = classroom.id;
          }
        });

      termList.map((c) => {
        if (c.title.toLowerCase() === newElement?.terms?.toLowerCase()) {
          termId = c.id;
        }
      });

      timingList.map((c) => {
        if (c.title.toLowerCase() === newElement?.timing?.toLowerCase()) {
          timingId = c.id;
        }
      });
      temp.push({
        id: i,
        childs_first_name: newElement.childs_first_name,
        childs_last_name: newElement.childs_last_name,
        childs_phone_number:
          newElement.primary_parent_phone_number ||
          newElement.childs_phone_number,
        city: newElement.city,
        country: newElement.country,
        country_id: countryId,
        state: newElement.state,
        dob:
          newElement.dob && Util.isValidDate(jsDate) ? jsDate : newElement.dob,
        nationality: newElement.nationality,
        nationality_id: nationalityId,
        primary_parent_first_name: newElement.primary_parent_first_name,
        primary_parent_last_name: newElement.primary_parent_last_name,
        primary_parent_email: newElement.primary_parent_email,
        primary_parent_relation: newElement.primary_parent_relation,
        primary_parent_relation_id: primaryrelationId,
        primary_parent_phone_number: newElement.primary_parent_phone_number,

        secondary_parent_first_name: newElement.secondary_parent_first_name,
        secondary_parent_last_name: newElement.secondary_parent_last_name,
        secondary_parent_email: newElement.secondary_parent_email,
        secondary_parent_relation: newElement.secondary_parent_relation,
        secondary_parent_relation_id: secondaryrelationId,
        secondary_parent_phone_number: newElement.secondary_parent_phone_number,

        days_per_week: daysArray,
        grade: newElement.grade,
        grade_id: gradeId,
        classroom: newElement.classroom,
        classroom_id: classroomId,
        level: newElement.level,
        level_id: stageId,

        timing: newElement.timing,
        timing_id: timingId,
        terms: newElement.terms,
        terms_id: termId,
      });
      i++;
    });

    setShowFileData(true);
    setFields(temp);
    setIsLoading(false);
    // console.log("=>", i, "=>", temp);
  };

  const uploadFile = () => {
    setIsLoading(true);
    setFieldError(false);
    const updatedFields = [...fields];
    var error = false;
    // console.log(updatedFields);
    updatedFields.map((val, index) => {
      if (
        val.childs_first_name == "" ||
        val.childs_last_name == "" ||
        val.dob == null ||
        val.dob == undefined ||
        !Util?.isValidDate(val.dob) ||
        // (val.pinCode !== "" &&
        //   val.pinCode !== undefined &&
        //   Util.Zip_regex.test(val.pinCode) === false)
        // ||
        val.primary_parent_first_name == "" ||
        val.primary_parent_last_name == "" ||
        val.primary_parent_email == "" ||
        val.primary_parent_phone_number == "" ||
        val.primary_parent_relation_id === 0 ||
        val.level_id === 0 ||
        val.grade_id === 0 ||
        val.classroom_id === 0
      ) {
        console.log("error", val);
        setFieldError(true);
        updatedFields[index].isEditing = true;
        error = true;
        setIsLoading(false);
      }
    });
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      updatedFields.map((val, index) => {
        const formData = new FormData();

        formData.append("first_name", val.childs_first_name);
        formData.append("last_name", val.childs_last_name);
        if (Util?.isValidDate(val.dob)) {
          formData.append("dob", new Date(val.dob).toISOString());
        }
        if (val.nationality_id) {
          formData.append("nationality_id", val.nationality_id);
        }
        if (val.country_id) {
          formData.append("country_id", val.country_id);
        }
        if (val.state) {
          formData.append("state", val.state);
        }
        if (val.city) {
          formData.append("city", val.city);
        }
        formData.append("classroom_id", val.classroom_id);

        if (val.grade_id) {
          formData.append("grade_id", val.grade_id);
        }
        if (val.timing_id) {
          formData.append("timing_id", val.timing_id);
        }
        if (val.terms_id) {
          formData.append("terms_id", val.terms_id);
        }
        if (val.primary_parent_first_name) {
          formData.append("parent_firstName", val.primary_parent_first_name);
        }
        if (val.primary_parent_last_name) {
          formData.append("parent_lastName", val.primary_parent_last_name);
        }
        if (val.primary_parent_relation_id) {
          formData.append(
            "student_parent_relation_type_id",
            val.primary_parent_relation_id
          );
        }
        if (val.primary_parent_phone_number) {
          formData.append("phone_number", val.primary_parent_phone_number);
        }
        if (val.primary_parent_email) {
          formData.append("parent_email", val.primary_parent_email);
        }
        if (val.secondary_parent_first_name) {
          formData.append(
            "secondary_parent_firstName",
            val.secondary_parent_first_name
          );
        }
        if (val.secondary_parent_last_name) {
          formData.append(
            "secondary_parent_lastName",
            val.secondary_parent_last_name
          );
        }
        if (val.secondary_parent_relation_id) {
          formData.append(
            "secondary_student_parent_relation_type_id",
            val.secondary_parent_relation_id
          );
        }
        if (val.secondary_parent_phone_number) {
          formData.append(
            "secondary_phone_number",
            val.secondary_parent_phone_number
          );
        }
        if (val.secondary_parent_email) {
          formData.append("secondary_parent_email", val.secondary_parent_email);
        }
        if (val.days_per_week) {
          days.forEach((day) => {
            const dayValue = day.value;

            const isChecked =
              val.days_per_week && val.days_per_week.includes(dayValue);
            formData.append(day.name.toLowerCase(), isChecked);
          });
        }

        // if (val.address1) {
        //   formData.append("address1", val.address1);
        // }
        // if (val.address2) {
        //   formData.append("address2", val.address2);
        // }

        // if (val.pinCode) {
        //   formData.append("pinCode", val.pinCode);
        // }

        // if (val.birth_place_id) {
        //   formData.append("birth_place_id", val.birth_place_id);
        // }
        // console.log("student", val);
        ApiCalling.apiCallBodyDataPost(
          "student/create-student-parent",
          formData
        )
          .then((res) => {
            updatedFields[index].isEditing = false;
            setFile("");
            setClassId(0);
            setGradeId(0);
            setIsEditFields(false);
            setIsStateUpdated(false);

            if (res.data?.status) {
              setIsClickedOnce(false);
              setIsLoading(false);
              // history.push({
              //   pendingToast: t("Student Created Successfully"),
              //   pathname: "/Student",
              // });
              setIsToast(t("Student Created Successfully"))
              setTriggerExit({
                path: "/student",
                onOk: true,
              });
            } else {
              // history.push({
              //   pathname: "/Student",
              //   pendingToast: res.data?.message || "Student Creation Failed",
              // });
              setIsToast(res.data?.message || "Student Creation Failed")
              setTriggerExit({
                path: "/student",
                onOk: true,
              });
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      });
    }
  };

  useEffect(() => {
    Stagelist();
    getRelationList();
    countrylist();
    Termslist();
    Timinglist();
    // allStateData();
    // allCityData();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
    if (hasRoom == undefined || hasRoom == null) {
      history.push("/student");
    }
  }, []);

  useEffect(() => {
    if (countryName.length > 0) {
      getStateList();
    }
  }, [countryName]);
  useEffect(() => {
    if (StateName.length > 0) {
      getCityList();
    }
  }, [StateName]);

  const handleGoToNextPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      setTimeout(() => {
        handleGoToNextPage(triggerExit.path);
      }, 0);
    }
    const unblock = history.block((location) => {
      if (location.pathname !== "/BulkStudent" && isStateUpdated) {
        setIsAlertModal(true);
      } else {
        return true;
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToNextPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isStateUpdated,
  ]);

  return (
    <div className="container-fluid">
      <div className="row mb-2 py-4">
        <BreadCrumbComponent
          Breadcrumb={[
            {
              name: "My School",
              navigate: () => {
                history.push("/Dashboard");
              },
            },
            {
              name: "Student List",
              navigate: () => {
                history.push("/student");
              },
            },
            { name: `${t("Add New Student Bulk")}` },
          ]}
        />
      </div>
      {!showFileData ? (
        <div className="container-80">
          {isLoading ? (
            <Loader />
          ) : hasRoom > 0 ? (
            <div className="body">
              <div className="col-lg mb-md-3 ">
                <h2 className="page-top-head text-left  ">
                  {t("Add New Student Bulk")}
                </h2>
              </div>
              <div className="row m-0">
                <div className="col-6">
                  <h2 className="page-head">{t("Upload Document")}</h2>
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center justify-content-end gap-1">
                    <img src={Download} />
                    <a
                      href={sampleFile}
                      target="_blank"
                      className="link-button"
                    >
                      {t("Download Template File")}
                    </a>
                  </div>
                </div>
                <div className=" text-start col-12 mt-4">
                  <div className="input-filed">
                    <span className="basic-text  required">
                      {t("Upload Document")}
                    </span>
                    <CustomFileUpload
                      onChange={handleFileChange}
                      fileName={file ? file.name : documentFileName}
                      showFile={fileURL.length > 0 ? fileURL : ""}
                      onDragOver={handleDocumentDragOver}
                      onDrop={handleDocumentDrop}
                      alertMessage={
                        documentFileName.length > 0 && (
                          <>
                            {t(
                              "incase you select new file it will replace old"
                            )}
                          </>
                        )
                      }
                      acceptedDocument="XLS,XLSX (max file 25mb)"
                      accept={".xlsx, .xls"}
                      className={`${
                        fileError.length > 0
                          ? "custom-file-error"
                          : "custom-file"
                      }`}
                    />
                    {fileError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {fileError}
                      </span>
                    )}
                  </div>
                  <div className=" d-flex justify-content-between mt-4">
                    <button
                      className="btn secondary-lg-btn"
                      onClick={() => {
                        history.push("/student");
                      }}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn custom-primary"
                      onClick={handleFileRead}
                    >
                      {t("Check Document")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="card col-lg-4">
                <div className="header">
                  <h3>
                    <span className="text">{t("You Can't Add Student")}</span>
                  </h3>
                </div>
                <div className="body">
                  <p>{t("There is no Room Structure")}.</p>
                  <div className="margin-top-10">
                    <a
                      className="btn custom-primary"
                      href="javascript:void(0)"
                      onClick={() => {
                        history.push("/settings");
                      }}
                    >
                      <i className="fa fa-home"></i>&nbsp;
                      <span>{t("Go To Create Room")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="row m-0">
          <div className="col-6">
            <h2 className="page-head">{t("Preview Document")}</h2>
          </div>
          <div className="col-6">
            <div className="d-flex align-items-center justify-content-end gap-1">
              <button
                className="btn btn-lg custom-secondary"
                onClick={() => {
                  setShowFileData(false);
                }}
              >
                {t("Back")}
              </button>
              <button
                className="btn btn-lg custom-primary"
                onClick={uploadFile}
              >
                {t("Save Student")}
              </button>
            </div>
          </div>
          {!isLoading ? (
            <div className="col-lg-12">
              <div className="table-div mt-2">
                <div className="table-wrapper">
                  <table className="table table-bulk-student">
                    <thead className="text-nowrap">
                      <tr>
                        <th>
                          {t("First Name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("first_name");
                            }}
                          />
                        </th>
                        <th>
                          {t("Last Name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("last_name");
                            }}
                          />
                        </th>
                        <th>
                          {t("Date of Birth")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("dob");
                            }}
                          />
                        </th>
                        <th>
                          {t("Nationality")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("nationality");
                            }}
                          />
                        </th>
                        <th>
                          {t("Country")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("country");
                            }}
                          />
                        </th>
                        <th>
                          {t("State")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("state");
                            }}
                          />
                        </th>
                        <th>
                          {t("City")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("city");
                            }}
                          />
                        </th>
                        <th>
                          {t("Level")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("level");
                            }}
                          />
                        </th>
                        <th>
                          {t("Grade")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("grade");
                            }}
                          />
                        </th>
                        <th>
                          {t("Classroom")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("classroom");
                            }}
                          />
                        </th>
                        <th>{t("Timing")}</th>
                        <th>{t("Days Per Week")}</th>
                        <th>{t("Terms")}</th>
                        {/* <th>
                          {t("Birth Place")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("birth_place");
                            }}
                          />
                        </th> */}
                        <th>
                          {t("Primary Parent first name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Primary Parent last name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Primary Parent Relation")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>{t("Primary Parent contact")}</th>
                        <th>
                          {t("Primary Parent email address")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>

                        <th>
                          {t("Secondary Parent first name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Secondary Parent last name")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>
                          {t("Secondary Parent Relation")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        <th>{t("Secondary Parent contact")}</th>
                        <th>
                          {t("Secondary Parent email address")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            // onClick={() => {
                            //   handleSort("contact");
                            // }}
                          />
                        </th>
                        {/* <th>
                          {t("Address 1")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("address1");
                            }}
                          />
                        </th>
                        <th>
                          {t("Address 2")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("address2");
                            }}
                          />
                        </th> */}

                        {/* <th>
                          {t("ZIP Code")}
                          <img
                            src={Sort}
                            className="ml-1 fa-sort"
                            alt=""
                            onClick={() => {
                              handleSort("pinCode");
                            }}
                          />
                        </th> */}

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedFields.map((field, index) => {
                        const isEditing = field.isEditing;
                        const currentClassroomList =
                          stageList
                            .map((stage) => stage.Grades)
                            ?.flat()
                            ?.find((gr) => gr.id === field.grade_id)
                            ?.ClassRooms || [];
                        // console.log("stageList", stageList);
                        // console.log(
                        //   "currentClassroomList",
                        //   currentClassroomList
                        // );
                        // console.log("***",Util?.isValidDate(fields[index].dob), fields[index])
                        return (
                          <tr key={index}>
                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.childs_first_name)
                                    ? field.childs_first_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.childs_first_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("First Name")}
                                      name="childs_first_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.childs_first_name
                                        ) ||
                                          field.childs_first_name.length ==
                                            0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.childs_first_name
                                      ) ||
                                        field.childs_first_name.length ==
                                          0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("First Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.childs_last_name)
                                    ? field.childs_last_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.childs_last_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Last Name")}
                                      name="childs_last_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.childs_last_name
                                        ) ||
                                          field.childs_last_name.length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.childs_last_name
                                      ) ||
                                        field.childs_last_name.length == 0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Last Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util?.isValidDate(fields[index].dob) ? (
                                      <>
                                        <img
                                          src={Calander}
                                          alt=""
                                          className="mr-1"
                                          style={{ cursor: "pointer" }}
                                        />{" "}
                                        {format(
                                          fields[index].dob,
                                          school.date_format
                                        )}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className=""
                                      style={{ minWidth: "180px" }}
                                    >
                                      <DatePicker
                                        placeholderText="Select Date"
                                        selected={
                                          Util?.isValidDate(fields[index].dob)
                                            ? fields[index].dob
                                            : null
                                        }
                                        // selected={
                                        //   fields[index].dob
                                        //     ? new Date(fields[index].dob)
                                        //     : null
                                        // }
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onChange={(date) =>
                                          handleInputChange(index, {
                                            target: {
                                              name: "dob",
                                              value: date,
                                            },
                                          })
                                        }
                                        dateFormat={school.date_format}
                                        // wrapperClassName={` ${
                                        //   dateOfBirthError.length > 0 ? "datePicker-error" : ""
                                        // }`}
                                        popperPlacement="bottom"
                                        maxDate={new Date()}
                                        customInput={
                                          <CustomInput data={field.dob} />
                                        }
                                      />
                                      {fieldError && field.dob == null && (
                                        <div
                                          className="text-danger"
                                          style={{ marginTop: "-8%" }}
                                        >
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Date Of Birth is required")}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.dob &&
                                    !Util?.isValidDate(fields[index].dob) && (
                                      <Tooltip
                                        title={`Invalid date (${field?.dob}). Format: DD-MM-YYYY`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.nationality) &&
                                    countryList.some(
                                      (c) =>
                                        c.nationality?.toLowerCase() ===
                                        field?.nationality?.toLowerCase()
                                    )
                                      ? field.nationality
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="nationality_id"
                                        onChange={(values) =>
                                          handleSelectNationalityChange(
                                            index,
                                            values
                                          )
                                        }
                                        options={countryList.map((option) => {
                                          return {
                                            id: option.id,
                                            value: option.nationality,
                                          };
                                        })}
                                        placeholder={
                                          countryList.find(
                                            (option) =>
                                              option?.nationality?.toLowerCase() ===
                                              field?.nationality?.toLowerCase()
                                          )?.nationality
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                countryList.find(
                                                  (option) =>
                                                    option?.nationality?.toLowerCase() ===
                                                    field?.nationality?.toLowerCase()
                                                )?.nationality
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.nationality &&
                                    field?.nationality_id === 0 && (
                                      <Tooltip
                                        title={`Invalid nationality (${field?.nationality})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.country) &&
                                    countryList.some(
                                      (c) =>
                                        c.name.toLowerCase() ===
                                        field?.country?.toLowerCase()
                                    )
                                      ? field.country
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="country_id"
                                        onChange={(values) =>
                                          handleSelectCountryChange(
                                            index,
                                            values
                                          )
                                        }
                                        options={countryList.map((option) => ({
                                          id: option.id,
                                          value: option?.name,
                                        }))}
                                        placeholder={
                                          countryList.find(
                                            (option) =>
                                              option?.name?.toLowerCase() ===
                                              field?.country?.toLowerCase()
                                          )?.name
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                countryList.find(
                                                  (option) =>
                                                    option?.name?.toLowerCase() ===
                                                    field?.country?.toLowerCase()
                                                )?.name
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.country &&
                                    field?.country_id === 0 && (
                                      <Tooltip
                                        title={`Invalid country (${field?.country})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.state)
                                    ? field.state
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.state}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("State")}
                                      name="state"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.city)
                                    ? field.city
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.city}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("City")}
                                      name="city"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            {/* LEVEL */}
                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.level) &&
                                    stageList &&
                                    stageList.some(
                                      (c) =>
                                        c.stage.toLowerCase() ===
                                        field?.level?.toString().toLowerCase()
                                    )
                                      ? field.level
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="grade_id"
                                        onChange={(values) =>
                                          handleStageChange(index, values)
                                        }
                                        options={stageList.map((level) => {
                                          return {
                                            id: level?.id,
                                            value: level?.stage,
                                          };
                                        })}
                                        placeholder={
                                          stageList.find(
                                            (option) =>
                                              option?.stage?.toLowerCase() ===
                                              field?.level
                                                ?.toString()
                                                .toLowerCase()
                                          )?.value
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                stageList.find(
                                                  (option) =>
                                                    option?.stage?.toLowerCase() ===
                                                    field?.level
                                                      ?.toString()
                                                      .toLowerCase()
                                                )?.stage
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                      {fieldError && field.level_id === 0 && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                            alt=""
                                          />
                                          {t("Level is required")}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                )}

                                <span className="mx-1">
                                  {field?.level && field?.level_id === 0 && (
                                    <Tooltip
                                      title={`Invalid Level (${field?.level})`}
                                      placement="right"
                                    >
                                      <img
                                        className="fa fa-exclamation-circle mr-1 invalid-svg"
                                        src={Invalid}
                                        alt=""
                                        style={
                                          isEditing
                                            ? {
                                                marginBottom: "25px",
                                              }
                                            : {}
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </span>
                              </div>
                            </td>

                            {/* GRADE */}
                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.grade) &&
                                    gradeList &&
                                    gradeList.some(
                                      (c) =>
                                        c.value.toLowerCase() ===
                                        field?.grade?.toString().toLowerCase()
                                    )
                                      ? field.grade
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="grade_id"
                                        onChange={(values) =>
                                          handleGradeChange(index, values)
                                        }
                                        options={
                                          // stageList
                                          // .map((val) => {
                                          //   return val.Grades.map((grade) => {
                                          //     return {
                                          //       id: grade?.id,
                                          //       value: grade?.title,
                                          //     };
                                          //   });
                                          // })
                                          // .flat()
                                          gradeLists.map((level) => {
                                            return {
                                              id: level?.id,
                                              value: level?.title,
                                            };
                                          })
                                        }
                                        placeholder={
                                          gradeList.find(
                                            (option) =>
                                              option?.value?.toLowerCase() ===
                                              field?.grade
                                                ?.toString()
                                                .toLowerCase()
                                          )?.value
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                gradeList.find(
                                                  (option) =>
                                                    option?.value?.toLowerCase() ===
                                                    field?.grade
                                                      ?.toString()
                                                      .toLowerCase()
                                                )?.value
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                      {fieldError && field.grade_id === 0 && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                            alt=""
                                          />
                                          {t("Grade is required")}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                )}

                                <span className="mx-1">
                                  {field?.grade && field?.grade_id === 0 && (
                                    <Tooltip
                                      title={`Invalid grade (${field?.grade})`}
                                      placement="right"
                                    >
                                      <img
                                        className="fa fa-exclamation-circle mr-1 invalid-svg"
                                        src={Invalid}
                                        alt=""
                                        style={
                                          isEditing
                                            ? {
                                                marginBottom: "25px",
                                              }
                                            : {}
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </span>
                              </div>
                            </td>

                            {/* CLASSROOM */}
                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.classroom) &&
                                    currentClassroomList &&
                                    currentClassroomList.some(
                                      (c) =>
                                        c.name.toLowerCase() ===
                                        field?.classroom
                                          ?.toString()
                                          .toLowerCase()
                                    )
                                      ? field.classroom
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="grade_id"
                                        onChange={(values) =>
                                          handleClassChange(index, values)
                                        }
                                        options={currentClassroomList.map(
                                          (cr) => {
                                            return {
                                              id: cr?.id,
                                              value: cr?.name,
                                            };
                                          }
                                        )}
                                        placeholder={
                                          currentClassroomList.find(
                                            (option) =>
                                              option?.name?.toLowerCase() ===
                                              field?.classroom
                                                ?.toString()
                                                .toLowerCase()
                                          )?.name
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                currentClassroomList.find(
                                                  (option) =>
                                                    option?.name?.toLowerCase() ===
                                                    field?.classroom
                                                      ?.toString()
                                                      .toLowerCase()
                                                )?.name
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                      {fieldError &&
                                        field.classroom_id === 0 && (
                                          <span className="text-danger invalid-error">
                                            <img
                                              className="fa fa-exclamation-circle mr-1 invalid-svg"
                                              src={Invalid}
                                              alt=""
                                            />
                                            {t("Classroom is required")}
                                          </span>
                                        )}
                                    </div>
                                  </>
                                )}

                                <span className="mx-1">
                                  {field?.classroom &&
                                    field?.classroom_id === 0 && (
                                      <Tooltip
                                        title={`Invalid classroom (${field?.classroom})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.timing) &&
                                    timingList &&
                                    timingList.some(
                                      (c) =>
                                        c.title.toLowerCase() ===
                                        field?.timing?.toString().toLowerCase()
                                    )
                                      ? field.timing
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="grade_id"
                                        onChange={(values) =>
                                          handleSelectTimingChange(
                                            index,
                                            values
                                          )
                                        }
                                        options={timingList.map((time) => {
                                          return {
                                            id: time?.id,
                                            value: time?.title,
                                          };
                                        })}
                                        placeholder={
                                          timingList.find(
                                            (option) =>
                                              option?.title?.toLowerCase() ===
                                              field?.timing
                                                ?.toString()
                                                .toLowerCase()
                                          )?.title
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                timingList.find(
                                                  (option) =>
                                                    option?.title?.toLowerCase() ===
                                                    field?.timing
                                                      ?.toString()
                                                      .toLowerCase()
                                                )?.title
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                )}

                                <span className="mx-1">
                                  {field?.timing && field?.timing_id === 0 && (
                                    <Tooltip
                                      title={`Invalid Level (${field?.timing})`}
                                      placement="right"
                                    >
                                      <img
                                        className="fa fa-exclamation-circle mr-1 invalid-svg"
                                        src={Invalid}
                                        alt=""
                                        style={
                                          isEditing
                                            ? {
                                                marginBottom: "25px",
                                              }
                                            : {}
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </span>
                              </div>
                            </td>

                            {/* <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  Util.isValidData(field.birth_place) &&
                                  countryList.some(
                                    (c) =>
                                      c.name.toLowerCase() ===
                                      field?.birth_place?.toLowerCase()
                                  ) ? (
                                    field.birth_place
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="birth_place_id"
                                        onChange={(values) =>
                                          handleSelectBirthPlaceChange(
                                            index,
                                            values
                                          )
                                        }
                                        options={countryList.map((option) => ({
                                          id: option.id,
                                          value: option.name,
                                        }))}
                                        placeholder={
                                          countryList.find(
                                            (option) =>
                                              option?.name?.toLowerCase() ===
                                              field?.birth_place?.toLowerCase()
                                          )?.name
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                countryList.find(
                                                  (option) =>
                                                    option.name?.toLowerCase() ===
                                                    field?.birth_place?.toLowerCase()
                                                )?.name
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.birth_place &&
                                    field?.birth_place_id === 0 && (
                                      <Tooltip
                                        title={`Invalid country (${field?.birth_place})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td> */}

                            <td>
                              <div
                                className={`input-filed ${
                                  !isEditing ? "mb-0" : "mt-2"
                                }`}
                              >
                                <div className="circle-container">
                                  {["M", "TU", "W", "TH", "F"].map((day) => (
                                    <label
                                      className={`circulercheckbox ${
                                        field.days_per_week &&
                                        field.days_per_week.includes(day)
                                          ? "checked"
                                          : ""
                                      }`}
                                      tabIndex={0}
                                      key={day}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          Array.isArray(field.days_per_week) &&
                                          field.days_per_week.includes(day)
                                        }
                                        onChange={() =>
                                          handleDayCheckboxChange(day, index)
                                        }
                                      />
                                      <span
                                        className={`circulercheckbox-letter ${
                                          field.days_per_week &&
                                          field.days_per_week.includes(day)
                                            ? "checked"
                                            : ""
                                        }`}
                                      >
                                        {day}
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.terms) &&
                                    termList &&
                                    termList.some(
                                      (c) =>
                                        c.title.toLowerCase() ===
                                        field?.terms?.toString().toLowerCase()
                                    )
                                      ? field.terms
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <Select
                                        className="select-custom w-fit select-bulk"
                                        name="grade_id"
                                        onChange={(values) =>
                                          handleSelectTermsChange(index, values)
                                        }
                                        options={termList.map((term) => {
                                          return {
                                            id: term?.id,
                                            value: term?.title,
                                          };
                                        })}
                                        placeholder={
                                          termList.find(
                                            (option) =>
                                              option?.title?.toLowerCase() ===
                                              field?.terms
                                                ?.toString()
                                                .toLowerCase()
                                          )?.title
                                        }
                                        searchable={true}
                                        labelField="value"
                                        valueField="id"
                                        searchBy="value"
                                        closeOnSelect={true}
                                        contentRenderer={({
                                          state,
                                          methods,
                                        }) => {
                                          return (
                                            <input
                                              placeholder={
                                                termList.find(
                                                  (option) =>
                                                    option?.title?.toLowerCase() ===
                                                    field?.terms
                                                      ?.toString()
                                                      .toLowerCase()
                                                )?.title
                                              }
                                              value={state.search}
                                              onChange={methods.setSearch}
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                )}

                                <span className="mx-1">
                                  {field?.terms && field?.terms_id === 0 && (
                                    <Tooltip
                                      title={`Invalid Level (${field?.terms})`}
                                      placement="right"
                                    >
                                      <img
                                        className="fa fa-exclamation-circle mr-1 invalid-svg"
                                        src={Invalid}
                                        alt=""
                                        style={
                                          isEditing
                                            ? {
                                                marginBottom: "25px",
                                              }
                                            : {}
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </span>
                              </div>
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.primary_parent_first_name
                                  )
                                    ? field.primary_parent_first_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.primary_parent_first_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("First Name")}
                                      name="primary_parent_first_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.primary_parent_first_name
                                        ) ||
                                          field.primary_parent_first_name
                                            .length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.primary_parent_first_name
                                      ) ||
                                        field.primary_parent_first_name
                                          .length == 0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("First Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.primary_parent_last_name
                                  )
                                    ? field.primary_parent_last_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.primary_parent_last_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Last Name")}
                                      name="primary_parent_last_name"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.primary_parent_last_name
                                        ) ||
                                          field.primary_parent_last_name
                                            .length == 0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.primary_parent_last_name
                                      ) ||
                                        field.primary_parent_last_name.length ==
                                          0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Last Name is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(
                                      field.primary_parent_relation
                                    ) &&
                                    relationList.some(
                                      (c) =>
                                        c.type.toLowerCase() ===
                                        field?.primary_parent_relation?.toLowerCase()
                                    )
                                      ? field.primary_parent_relation
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <select
                                        className={`form-select select-dropdown  ${
                                          fieldError &&
                                          field.primary_relation_id === 0
                                            ? "input-error"
                                            : "custom-input"
                                        }`}
                                        name="relation_id"
                                        onChange={(event, i) =>
                                          handleSelectRelationChange(
                                            index,
                                            event
                                          )
                                        }
                                        style={{ minWidth: "150px" }}
                                      >
                                        <option value={0}>{t("Select")}</option>
                                        {relationList.map((option) => {
                                          if (
                                            field?.primary_parent_relation?.toLowerCase() ===
                                            option.type.toLowerCase()
                                          ) {
                                            return (
                                              <option
                                                selected
                                                value={option.id}
                                                key={option.id}
                                              >
                                                {option.type}
                                              </option>
                                            );
                                          } else {
                                            return (
                                              <option
                                                value={option.id}
                                                key={option.id}
                                              >
                                                {option.type}
                                              </option>
                                            );
                                          }
                                        })}
                                      </select>
                                      {fieldError &&
                                        field.primary_relation_id === 0 && (
                                          <span className="text-danger invalid-error">
                                            <img
                                              className="fa fa-exclamation-circle mr-1 invalid-svg"
                                              src={Invalid}
                                            />
                                            {t("Relation is required")}
                                          </span>
                                        )}
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.primary_relation &&
                                    !Util.isValidRelation(
                                      field?.primary_relation
                                    ) && (
                                      <Tooltip
                                        title={`Invalid relation (${field?.primary_parent_email})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.primary_parent_phone_number
                                  ) ? (
                                    <>
                                      <img
                                        src={Call}
                                        alt=""
                                        width={"16px"}
                                        className="mr-1"
                                      />
                                      {field.primary_parent_phone_number}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.primary_parent_phone_number}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("contact")}
                                      name="primary_parent_phone_number"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.primary_parent_email)
                                    ? field.primary_parent_email
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.primary_parent_email}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Email Address")}
                                      name="primary_parent_email"
                                      className={`form-control custom-input ${
                                        fieldError &&
                                        (!Util.isValidData(
                                          field.primary_parent_email
                                        ) ||
                                          field.primary_parent_email.length ==
                                            0) &&
                                        "input-error"
                                      }`}
                                    />
                                    {fieldError &&
                                      (!Util.isValidData(
                                        field.primary_parent_email
                                      ) ||
                                        field.primary_parent_email.length ==
                                          0) && (
                                        <span className="text-danger invalid-error">
                                          <img
                                            className="fa fa-exclamation-circle mr-1 invalid-svg"
                                            src={Invalid}
                                          />
                                          {t("Email is required")}
                                        </span>
                                      )}
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.secondary_parent_first_name
                                  )
                                    ? field.secondary_parent_first_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.secondary_parent_first_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("First Name")}
                                      name="secondary_parent_first_name"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.secondary_parent_last_name
                                  )
                                    ? field.secondary_parent_last_name
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.secondary_parent_last_name}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Last Name")}
                                      name="secondary_parent_last_name"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            {/* <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.address1)
                                    ? field.address1
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.address1}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("address 1")}
                                      name="address1"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(field.address2)
                                    ? field.address2
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.address2}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("address 2")}
                                      name="address2"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td> */}

                            {/* <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(field.pinCode)
                                      ? field.pinCode
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <input
                                        style={{
                                          fontSize: "13px",
                                          minWidth: "150px",
                                        }}
                                        type="number"
                                        value={field.pinCode}
                                        onChange={(event) =>
                                          handleInputChange(index, event)
                                        }
                                        placeholder={t("PinCode")}
                                        name="pinCode"
                                        className={`form-control custom-input`}
                                      />
                                      {fieldError &&
                                        field.pinCode !== "" &&
                                        field.pinCode !== undefined &&
                                        Util.Zip_regex.test(field.pinCode) ===
                                          false && (
                                          <span
                                            style={{ width: "150px" }}
                                            className="text-danger"
                                          >
                                            <img
                                              className="fa fa-exclamation-circle mr-1 invalid-svg"
                                              src={Invalid}
                                            />
                                            {t(
                                              "Please enter valid zip-code(i.e: 12345 or 12345-2563)"
                                            )}
                                          </span>
                                        )}
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.pinCode &&
                                    !Util.Zip_regex.test(field?.pinCode) && (
                                      <Tooltip
                                        title={`Invalid pinCode (${field?.pinCode})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td> */}
                            <td>
                              <div className="d-flex align-items-center">
                                {!isEditing ? (
                                  <>
                                    {Util.isValidData(
                                      field.secondary_parent_relation
                                    ) &&
                                    relationList.some(
                                      (c) =>
                                        c.type.toLowerCase() ===
                                        field?.secondary_parent_relation?.toLowerCase()
                                    )
                                      ? field.secondary_parent_relation
                                      : "-"}
                                  </>
                                ) : (
                                  <>
                                    <div className="input-filed">
                                      <select
                                        className="form-select select-dropdown"
                                        name="relation_id"
                                        onChange={(event, i) =>
                                          handleSelectRelationChange(
                                            index,
                                            event
                                          )
                                        }
                                        style={{ minWidth: "150px" }}
                                      >
                                        <option value={0}>{t("Select")}</option>
                                        {relationList.map((option) => {
                                          if (
                                            field?.relation?.toLowerCase() ===
                                            option.type.toLowerCase()
                                          ) {
                                            return (
                                              <option
                                                selected
                                                value={option.id}
                                                key={option.id}
                                              >
                                                {option.type}
                                              </option>
                                            );
                                          } else {
                                            return (
                                              <option
                                                value={option.id}
                                                key={option.id}
                                              >
                                                {option.type}
                                              </option>
                                            );
                                          }
                                        })}
                                      </select>
                                    </div>
                                  </>
                                )}
                                <span className="mx-1">
                                  {field?.secondary_parent_relation &&
                                    !Util.isValidRelation(
                                      field?.secondary_parent_relation
                                    ) && (
                                      <Tooltip
                                        title={`Invalid relation (${field?.secondary_parent_email})`}
                                        placement="right"
                                      >
                                        <img
                                          className="fa fa-exclamation-circle mr-1 invalid-svg"
                                          src={Invalid}
                                          alt=""
                                          style={
                                            isEditing
                                              ? {
                                                  marginBottom: "25px",
                                                }
                                              : {}
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              </div>
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.secondary_parent_phone_number
                                  ) ? (
                                    <>
                                      <img
                                        src={Call}
                                        alt=""
                                        width={"16px"}
                                        className="mr-1"
                                      />
                                      {field.secondary_parent_phone_number}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={
                                        field.secondary_parent_phone_number
                                      }
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("contact")}
                                      name="secondary_parent_phone_number"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td>
                              {!isEditing ? (
                                <>
                                  {Util.isValidData(
                                    field.secondary_parent_email
                                  )
                                    ? field.secondary_parent_email
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  <div className="input-filed">
                                    <input
                                      style={{
                                        fontSize: "13px",
                                        minWidth: "150px",
                                      }}
                                      type="text"
                                      value={field.secondary_parent_email}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={t("Email Address")}
                                      name="secondary_parent_email"
                                      className="form-control custom-input"
                                    />
                                  </div>
                                </>
                              )}
                            </td>

                            <td
                              style={{
                                verticalAlign: `${isEditing ? "top" : ""}`,
                              }}
                            >
                              {!isEditing ? (
                                <div className="d-flex align-items-center gap-2">
                                  <i
                                    className="fa fa-pencil cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      handleEditClick(index);
                                    }}
                                  ></i>
                                  <div className="dropdown">
                                    <button
                                      // className="btn btn-lg custom-primary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      style={{ backgroundColor: "#fff" }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      ></i>
                                    </button>

                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleRemoveField(index)
                                          }
                                        >
                                          {t("Delete")}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <div style={{ marginTop: "25%" }}>
                                  <i
                                    className="fa fa-check mr-3 text-success cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      handleCancelClick(index);
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-times text-danger cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      handleCancelClick(index);
                                    }}
                                  ></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="px-3 py-2">{sortedFields.length} Contacts</div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
      <ExitAlert
        visible={alertModal}
        onClose={() => setIsAlertModal(false)}
        onOk={() => {
          setTriggerExit((obj) => ({
            ...obj,
            onOk: true,
          }));
          setIsAlertModal(false);
        }}
      />
    </div>
  );
}
export default ToastHOC(BulkStudent);
