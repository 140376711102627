import React from "react";
import { useState, useEffect } from "react";
import Close from "../../../assets/Icon/close.png";
import { useTranslation } from "react-i18next";
import Sort from "../../../assets/Icon/Short.svg";
import Loader from "../../../components/common/Loader";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import ModalComponent from "../ModalComponent";
import Invalid from "../../../assets/Icon/invalid.svg";
import ModalContent from "../../../components/common/ModalContent";
import { Modal, Button } from "react-bootstrap";
import ToastHOC from "../../HOC/ToastHOC";

const PaymentInfo = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showPaymentKeyModal, setShowPaymentKeyModal] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [privateKeyName, setPrivateKeyName] = useState("");
  const [privateKeyNameError, setPrivateKeyNameError] = useState("");
  const [publicKeyName, setPublicKeyName] = useState("");
  const [publicKeyNameError, setPublicKeyNameError] = useState("");
  const [keyList, setKeyList] = useState([]);
  const { setIsToast } = props;

  useEffect(() => {
    const school = Util.getSelectedSchool();
    if (school) {
      setSelectedSchool(school?.id);
    }
  }, []);

  const getPublishKey = async() => {
    ApiCalling.apiCallBodyDataGet("stripe-key/get-key", selectedSchool)
      .then((res) => {
        if (res.data.status == true) {
          setKeyList([res.data]);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPublishKey();
  }, []);

  const keyHandleShow = () => {
    setShowPaymentKeyModal(true);
    setEditValue(null);
    setPrivateKeyName("");
    setPrivateKeyNameError("");
    setPublicKeyName("");
    setPublicKeyNameError("");
  };

  const handleAddClose = () => {
    setShowPaymentKeyModal(false);
    setPrivateKeyName("");
    setPrivateKeyNameError("");
    setPublicKeyName("");
    setPublicKeyNameError("");
  };

  const keyHandleSaveAndClose = () => {
    setPublicKeyNameError("");
    setPrivateKeyNameError("");
    let error = false;
    if (privateKeyName == "") {
      setPrivateKeyNameError(t("Private Key is required"));
      error = true;
    }
    if (publicKeyName == "") {
      setPublicKeyNameError(t("Public Key is required"));
      error = true;
    }
    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);

      const data = {
        public: publicKeyName,
        private: privateKeyName,
      };
      // console.log(data);

      ApiCalling.apiCallBodyDataPost("stripe-key/add", data, selectedSchool)
        .then((res) => {
          console.log(res);
          if (res.data.status === true) {
            setIsLoading(false);
            setIsToast(res.data.message);
            setShowPaymentKeyModal(false);
            getPublishKey();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };
  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="header text-right mb-3 px-0">
              <button
                className={`${
                  Util.hasPermission("MASTERS_ADD")
                    ? "btn custom-primary"
                    : "no-permission"
                }`}
                type="button"
                aria-expanded="false"
                style={{ color: "#fff", padding: "5px, 10px" }}
                onClick={keyHandleShow}
              >
                {t("Register new Stripe Key")}
              </button>

              <Modal
                show={showPaymentKeyModal}
                onHide={handleAddClose}
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                onShow={() => {
                  setIsClickedOnce(false);
                  // if (editValue !== null) {
                  //   setNewMealDescription(editValue.description);
                  //   setNewMealName(editValue.title);
                  // }
                }}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>
                    {editValue !== null
                      ? t("Edit Stripe Key")
                      : t("Register Stripe Key")}
                  </h6>

                  <button className="hidden-btn" onClick={handleAddClose}>
                    <img
                      src={Close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <ModalContent isLoading={isLoading}>
                  <Modal.Body>
                    <>
                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Private Stripe Key")}
                        </label>
                        <input
                          className={`form-control custom-input ${
                            privateKeyNameError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={privateKeyName}
                          onChange={(event) => {
                            setPrivateKeyName(event.target.value);
                            setPrivateKeyNameError("");
                          }}
                        />
                        {privateKeyNameError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {privateKeyNameError}
                          </span>
                        )}
                      </div>

                      <div className="input-filed text-start">
                        <label className="basic-text required">
                          {t("Public Stripe Key")}
                        </label>
                        <input
                          className={`form-control custom-input ${
                            publicKeyNameError.length > 0 && "input-error"
                          }`}
                          type="text"
                          value={publicKeyName}
                          onChange={(event) => {
                            setPublicKeyName(event.target.value);
                            setPublicKeyNameError("");
                          }}
                        />
                        {publicKeyNameError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {publicKeyNameError}
                          </span>
                        )}
                      </div>
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn custom-primary"
                      onClick={keyHandleSaveAndClose}
                    >
                      {t("Save")}
                    </Button>
                  </Modal.Footer>
                </ModalContent>
              </Modal>
            </div>
          </div>
          <div className="row clearfix ">
            <div className="table-div overflow-hidden  p-0">
              <div className="col-lg-12 col-md-12 p-0 m-0 ">
                <div className="body">
                  {!isLoading ? (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th scope="col">
                              {t("Key")}{" "}
                              <img
                                src={Sort}
                                className="ml-1 fa-sort"
                                alt=""
                                // onClick={() => {
                                //   handleSort("Description");
                                // }}
                              />
                            </th>
                          </tr>
                        </thead>
                        {keyList.length > 0 ? (
                          <>
                            <tbody>
                              {keyList.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.key}</td>
                                </tr>
                              ))}
                            </tbody>
                          </>
                        ) : (
                          <>
                            <tfoot>
                              <tr>
                                <td colSpan={7}>
                                  {t("There are no Stripe Keys")}
                                </td>
                              </tr>
                            </tfoot>
                          </>
                        )}
                      </table>
                      {keyList.length > 0 && (
                        <div className="text-muted pl-3 mb-3 border-top py-3">
                          <i>
                            {keyList.length} {t("Key found")}.
                          </i>
                        </div>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(PaymentInfo);
